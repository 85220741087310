import React from "react";
import {Route, Routes } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Product from "./Pages/Product/Product";
import About from "./Pages/About/About";
import Blog from "./Pages/Blog/Blog";
import Contact from "./Pages/Contact/Contact";
import Casestudy from "./Pages/Casestudy/Casestudy";
import CardView from "./Components/CardView/CardView";
import ViewBlog from "./Components/BlogesMain/ViewBlog";
// import StoShare from "./Components/StoShare/StoShare";
import FirstBlog from "./Components/BlogPages/FirstBlog";
import SecoendBlog from "./Components/BlogPages/SecoendBlog/SecoendBlog";
import ThardBlog from "./Components/BlogPages/ThardBlog/ThardBlog";
import FourthBlog from "./Components/BlogPages/FourthBlog/Fourstblog";
import Fiveblog from "./Components/BlogPages/FiveBlog/Fiveblog";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/product" element={<Product />} />
      <Route path="/about" element={<About />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/casestudy" element={<Casestudy />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/view-card" element={<CardView />} />
      <Route path="/viewblog" element={<ViewBlog />} />
      {/* <Route path="/stoshare" element={<StoShare />} /> */}
      <Route path="/FirstBlog" element={<FirstBlog />} />
      <Route path="/SecoendBlog" element={<SecoendBlog />} />
      <Route path="/ThardBlog" element={<ThardBlog />} />
      <Route path="/FourthBlog" element={<FourthBlog />} />
      <Route path="/Fiveblog" element={<Fiveblog />} />
    </Routes>
  );
};

export default App;
