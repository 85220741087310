import React from "react";
import "./secoendscetion.css";
import sec2 from "../../../assets/homepage/Artboard.png"
// import OpenClick from "../../StoShare/OpenClick";
const Secoendscetion = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center con-heading">
            <h1>AGEP's Advanced Coating Solutions</h1>
          </div>
        {/* </div>
        <div className="row"> */}
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 image-column">
            {/* <!-- Image Column --> */}
            <img src={sec2} className="img-fluid" alt="_coating" />
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
            {/* <!-- Content Column --> */}
            <div className="content-column p-2">
              <h2>  Global Solutions for Industrial Coating Needs</h2>
              <p>
              AGEP Coatings is at the forefront of providing cutting-edge corrosion control and reliability management solutions across various industries, including Thermal Power, Oil & Gas, and Marine. Our commitment to excellence ensures that our clients receive the most effective and innovative solutions to protect their assets from the damaging effects of corrosion. With a strong focus on quality and performance, AGEP Coatings continues to lead the industry in delivering reliable and durable protection.</p>
              <a href="/Product" className="top-btn text-black p-2">Explore Our Products</a>
              {/* <OpenClick /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Secoendscetion;
