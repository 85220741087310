import React from "react";
import "./fiveblog.css";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";

const Fiveblog = () => {
  return (
    <>
      <Header />
      <div className="container-fluid fiveblog">
        <div className="row">
          <div className="blog-content text-light">
            <h1>
              DESIGN CONSIDERATIONS AND SUITABILITY OF RAPIDFIX HD COMPOSITE FOR
              APPLICATION ON CORRODED OLD LEAKEDPIPELINES
            </h1>
            <a href="/Blog">
              <button type="button" className="slide-button text-light">
                Know More
              </button>
            </a>
          </div>
        </div>
      </div>

      <div className="container tharddetails">
        <div className="row">
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xm-12">
            <h5>Written by</h5>
            <p>Harshvardhan Garge</p>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xm-12">
            <h5>Published on</h5>
            <p>02th July, 2024</p>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xm-12">
            <h5>Read time</h5>
            <p> 7 minute</p>
          </div>
          <div className="col-xl-12 col-lg-12 col-sm-12 col-xm-12 col-12 tharddetailsblog mt-5">
            <h3>Abstract</h3>
            <p>
              RapidFix HD Composite system comprises of carefully selected
              reinforcing material with RapidCure UW – a modified copolymer
              epoxy – used as matrix to deliver high performance in variety of
              design considerations of industrial equipment such as tanks,
              process vessels, piping, structural reinforcement and many such
              components where structural stability is an added requirement to
              corrosion protection. This document describes design
              considerations and discusses suitability of RapidFix HD Composite
              to DLF Mall Of India chiller pipelines repair application and
              application as a full system.
            </p>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xm-12">
            <img
              src="https://agepcoatings.com/Uploads/blog/05rapidfixComositor/01.png"
              className="img-fluid"
              alt="Initial Condition of Valves"
            />
            <p>Initial Condition of Valves</p>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xm-12">
            <img
              src="https://agepcoatings.com/Uploads/blog/05rapidfixComositor/02.png"
              className="img-fluid"
              alt="AnotherImage"
            />
          </div>
        </div>
      </div>

      <div className="container tharddetailsblog">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-sm-12 col-xm-12 col-12 mt-5">
            <h3>Design Considerations</h3>
            <p>
              Following design parameters were considered while verifying
              suitability of RapidFix HD Composite for the application as a full
              system on leaked, punctured steel pipelines.
            </p>
          </div>
          <div className="col-xl-12 col-lg-12 col-sm-12 col-xm-12 col-12">
            <table className="table table-striped table-bordered">
              <tbody>
                <tr>
                  <td>Pipelines</td>
                  <td>Chiller Pipelines</td>
                </tr>
                <tr>
                  <td>Substrate</td>
                  <td>Carbon Steel</td>
                </tr>
                <tr>
                  <td>Media</td>
                  <td>Chemically Treated Water</td>
                </tr>
                <tr>
                  <td>Design Temperature</td>
                  <td>100°C</td>
                </tr>
                <tr>
                  <td>Operating Temperature</td>
                  <td>10°C - 80°C</td>
                </tr>
                <tr>
                  <td>Operating Environment Classification</td>
                  <td>
                    IM4 Sea or Brackish Water with Cathodic Protection (ISO
                    12944)
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              Concerning the repair system to be applied at DLF Mall of India,
              Chiller Pipelines following parameters were considered.
            </p>
            <table className="table table-striped table-bordered">
              <tbody>
                <tr>
                  <td>Existing Condition</td>
                  <td>Corroded, Pitted with leakages</td>
                </tr>
                <tr>
                  <td>Stage of pipelines</td>
                  <td>Fully Operated</td>
                </tr>
                <tr>
                  <td>Suitability of repair compound</td>
                  <td>Must be able to adhere to old pipelines surface</td>
                </tr>
                <tr>
                  <td>Thickness of Laminate</td>
                  <td>
                    Must meet NACE No. 10 single laminate thickness requirements
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="container tharddetailsblog">
        <div className="row">
          <div className="col-12 mt-5">
            <h3>
              Additionally, following challenges were considered while selection
              of repair system.
            </h3>
            <ul>
              <li>
                Application of system in the installed condition of pipelines be
                considering only once the pipelines are isolated and in shutdown
                condition if leakages are present. If leakages are not present
                than no requirement of shutdown.
              </li>
              <li>
                Applicability of the system in high relative humidity conditions
                at the time of application/installation (85%)
              </li>
              <li>
                Temperature fluctuations during operation, requirement of
                flexibility in the system.
              </li>
            </ul>
            <h3>Suitability of RapidFix HD Composite system:</h3>
            <p>
              RapidFix HD Composite system comprises of 3 main components, metal
              repair compound, reinforcing system and matrix. Final product
              meets the design and performance requirements of ISO 24817, NACE
              SP 0304 and NACE No. 11.
            </p>
            <p className="mt-2">
              Following comparison table provides suitability statement for
              RapidFix HD Composite.
            </p>
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th scope="col-4">Parameter</th>
                  <th scope="col-4">Design Parameter</th>
                  <th scope="col-4">RapidFix HD Composite</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Type of system</td>
                  <td>Epoxy Copolymer Epoxy Novolac Reactive Polyester</td>
                  <td>Epoxy Copolymer modified with Zirconia</td>
                </tr>
                <tr>
                  <td>Reinforcing System</td>
                  <td>Glass Fiber</td>
                  <td>
                    Suitable with glass fiber, Kevlar, carbon fiber, basalt and
                    polyester
                  </td>
                </tr>
                <tr>
                  <td>Design Temperature</td>
                  <td>150°C</td>
                  <td>150°C</td>
                </tr>
                <tr>
                  <td>Long Term Durability Test</td>
                  <td>-</td>
                  <td>NORSOK M501</td>
                </tr>
                <tr>
                  <td>Application Temperature</td>
                  <td>Ambient less than 50°C</td>
                  <td>Ambient up to 150°C</td>
                </tr>
                <tr>
                  <td>Application Tolerance</td>
                  <td>-</td>
                  <td>Surface tolerant mastic, capable of sealing porosity</td>
                </tr>
                <tr>
                  <td>Relative Humidity Tolerance</td>
                  <td>Max 85%</td>
                  <td>Less than 98%</td>
                </tr>
                <tr>
                  <td>Thickness (NACE No. 10)</td>
                  <td>1000 micron</td>
                  <td>
                    1000 micron in single laminate 2000 – 2200 micron in double
                    laminate
                  </td>
                </tr>
                <tr>
                  <td>Elongation at break (ASTM D 3039)</td>
                  <td>-</td>
                  <td>0.8% in glass fiber 1% in Kevlar</td>
                </tr>
                <tr>
                  <td>Linear Thermal Expansion (ASTM E 831)</td>
                  <td>-</td>
                  <td>
                    2.17 x 10-4 mm/mm/°C for glass fiber
                    <br />
                    2.79 x 10-4 mm/mm/°C for Kevlar
                  </td>
                </tr>
                <tr>
                  <td>Scrub Resistance by Abrasion (ASTM D4213)</td>
                  <td>-</td>
                  <td>
                    No. of scrub cycles: 1,441,613 cycles
                    <br />
                    Abrasion load: 4135 kg
                    <br />
                    Weight loss: 1g
                    <br />
                    Wear index: 2.87
                  </td>
                </tr>
                <tr>
                  <td>Compression Load</td>
                  <td>-</td>
                  <td>
                    16,976 psi for Glass
                    <br />
                    22,301 psi for Kevlar
                  </td>
                </tr>
                <tr>
                  <td>Shear Load</td>
                  <td>-</td>
                  <td>11,079 psi</td>
                </tr>
                <tr>
                  <td>Cathodic Disbondment (ASTM G 8)</td>
                  <td>-</td>
                  <td>Avg 5.8mm / 28 day / NaCl / 45°C</td>
                </tr>
                <tr>
                  <td>Applicability over existing coating</td>
                  <td>Aged Fiberglass Epoxy</td>
                  <td>
                    Suitable by pull-off adhesion testing when applied on SSPC
                    SP3 / ISO 8501-1 St 3 substrate with minimum roughness value
                    of Ra 12.5
                  </td>
                </tr>
                <tr>
                  <td>Curing schedule required taking in operation</td>
                  <td>-</td>
                  <td>Unrestricted after touch-dry</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-xl-4 col-lg-4 col-sm-12 col-12 col-xm-12">
            <img
              src="https://agepcoatings.com/Uploads/blog/05rapidfixComositor/03.png"
              className="img-fluid"
              alt="Primer Application"
            />
            <p>Primer Application</p>
          </div>
          <div className="col-xl-4 col-lg-4 col-sm-12 col-12 col-xm-12">
            <img
              src="https://agepcoatings.com/Uploads/blog/05rapidfixComositor/04.png"
              className="img-fluid"
              alt="Top Coat Application"
            />
            <p>Top Coat Application</p>
          </div>
          <div className="col-xl-4 col-lg-4 col-sm-12 col-12 col-xm-12">
            <img
              src="https://agepcoatings.com/Uploads/blog/05rapidfixComositor/06.png"
              className="img-fluid"
              alt="AnotherImage"
            />
          </div>
          <div className=" col-xl-12 col-lg-12 col-sm-12 col-12 col-xm-12">
            <p>
              RapidFix HD Composites and RapidCure UW are applied in India and
              SE Asia since 2003 for following service conditions:
            </p>
            <ol>
              <li>
                Underwater riser-leg strengthening of Petronas offshore
                production platforms in Malaysia since 2003 – multiple projects
                – RapidFix HD Composite
              </li>
              <li>
                Lining of sour crude oil storage tanks in Kemaman, Malaysia
                since 2003 – RapidCure UW
              </li>
              <li>
                Underwater jetty piling restrengthening – PT Indonesia year 2013
                – RapidFix HD Composite
              </li>
              <li>
                Sea water ballast tank of Duta Marine Pacific – China year 2013
                – RapidCure UW
              </li>
              <li>
                Waste-water treatment aeration tank linings at SIPCOT Industrial
                Area Tamil Nadu, India in the year 2016 – RapidCure UW
              </li>
              <li>
                Lining of concrete tanks used for primary treatment,
                equalization and drain pits of Singapore Public Utility Board in
                the year 2018 – RapidCure UW
              </li>
              <li>
                Corrosion protection of sea water inlet lines of TNB Janamanjung
                (Power Plant) year 2019 – RapidFix HD Composite
              </li>
              <li>
                Pipe Strengthening work at Prayagraj Power Generation Company
                Ltd, Bara, Compressor House Water Pipelines, C&W Pipelines.
              </li>
            </ol>
            <h3>Design of the System</h3>
            <p>
              System is designed to meet all above technical parameters
              mentioned in comparison chart and designed to give 10 -15 Years of
              Service Life in the exposed environmental conditions i.e. weather
              conditions, ambient temperature changes , rain water and treated
              water passing through the pipelines.
            </p>
            <h3>Procedure Followed</h3>
            <ol>
              <li>
                <strong>Surface Preparation : </strong>Surface was prepared as
                per ST 2 Standard (Thorough Hand and power tool cleaning)
              </li>
              <li>
                <strong>Primer – </strong>RapidCure UW was applied on rest of
                the prepared surface to a DFT of 100 Microns and wait for
                surface to be tacky almost 30 minutes
              </li>
              <li>
                <strong>HD Composite Material : </strong> RapidCure HD Composite
                Mat GF90 was saturated with RapidCure UW and wrapped over the
                surface tightly. Metal Rollers were used to remove all air from
                the wrapping.
              </li>
              <li>
                <strong>Stretch Wrap : </strong>RapidCure HD Composite mat after
                wrapping was wrapped with stretch wrap on the surface and was
                allowed to cure for minimum 8 Hours.
              </li>
              <li>
                <strong>Removal of Stretch Wrap : </strong>After curing the
                stretch wrap was removed and top coat with DFT of 100 microns
                was applied.
              </li>
              <li>Application Completed.</li>
            </ol>
            <h3>Final Result :</h3>
            <p>
              Pipelines were bring into operating conditions and no leakages
              were found.
            </p>
            <h3>Design of the System</h3>
            <p>
              System is designed to meet all above technical parameters
              mentioned in comparison chart and designed to give 10 -15 Years of
              Service Life in the exposed environmental conditions i.e. weather
              conditions, ambient temperature changes , rain water and treated
              water passing through the pipelines.
            </p>
            <h3>Advantages of System :</h3>
            <ol>
              <li>Cost effective i.e. economical.</li>
              <li>Less Shutdown time.</li>
              <li>Less Manpower Involvement.</li>
              <li>Single Product for whole process of application.</li>
              <li>Faster.</li>
              <li>
                No Mobilization of big spool pipes to work location, cutting ,
                buffing, welding hot work which is too much time consuming, too
                many manpower and process involved along with too high
                replacement cost. Also shutdown cost had to be taken into
                consideration.
              </li>
              <li>Service life of 10-15 Years with warranty of 7 Years.</li>
            </ol>
            <h3>Enclosure</h3>
            <p>
              RapidFix HD Composite Data Sheet <br />
              RapidCure UW Data Sheet
              <br />
              RapidCure Steel Filler Data Sheet
              <br />
            </p>
          </div>
        </div>
      </div>

      <div className="container tharddetailsblog">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xm-12">
            <img
              src="https://agepcoatings.com/Uploads/blog/05rapidfixComositor/07.png"
              className="img-fluid"
              alt="AnotherImage"
            />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xm-12">
            <img
              src="https://agepcoatings.com/Uploads/blog/05rapidfixComositor/08.png"
              className="img-fluid"
              alt="AnotherImage"
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Fiveblog;
