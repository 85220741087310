import React from "react";
import "./sevensection.css";

const Sevensection = () => {
  return (
    <>
      <div className="container-fluid w-100 overflow-hidden seven-bg">
        <div className="row d-flex align-items-center justify-content-center">
          <div className="seven-contect text-center">
            <h3>
              Our global team of technical and sales experts are available to
              provide you with the help and support that you need.
            </h3>
            <p className="seven-lead">We look forward to hearing from you!</p>
               <a href="/Contact">
                  <button type="button" className="slide-button text-light">
                    Contact Now
                  </button>
                </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sevensection;
