import React from 'react'
import "./fourthsection.css"
import sec4 from "../../../assets/homepage/06.png"
import rapid from "../../../assets//homepage/rapidfix.png"

const Fourthsection = () => {
  return (
    <>
     <div className="container con-heading">
        <div className="row">
          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12 text-center">
            <h1>Feature Product</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 image-column">
            {/* <!-- Image Column --> */}
            <img src={sec4} className="img-fluid" alt="_coating" />
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
            {/* <!-- Content Column --> */}
            <div className='content-column'>
              <img src={rapid} alt='coating-ig' className='img-fluid mr-5' width="30%" />
              <h2>Flange Protection at it's Best</h2>
              <p>
              Flange Protection offers an optimal solution for those in search of complete protection against corrosion for their flanges & gaskets. Its advanced design ensures a highly durable and reliable barrier against even the harsh climate conditions, thus ensuring that your equipment remains in optimal condition for extended periods.
              </p>
              <a href='/Product' className="top-btn text-black p-2">Explore Our Products</a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Fourthsection