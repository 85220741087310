import React from "react";
import "./thardblog.css";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";

const ThardBlog = () => {
  return (
    <>
      <Header />
      <div className="container-fluid thardblog">
        <div className="row">
          <div className="blog-content text-light">
            <h1>
              DESIGN CONSIDERATIONS AND SUITABILITY OF RAPIDCURE SEALER FOR
              APPLICATION ON ALUMINIUM CHLORIDE HANDLING PLANTS & UNITS
            </h1>
            <a href="/Blog">
              <button type="button" className="slide-button text-light">
                Know More
              </button>
            </a>
          </div>
        </div> 
      </div>
      <div className="container tharddetails">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12 col-12 d-flex">
            <h5>Written by</h5>
            <p>Harshvardhan Garge</p>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-12 d-flex">
            <h5>Published on</h5>
            <p>02th July, 2024</p>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-12 d-flex">
            <h5>Read time</h5>
            <p>7 minute</p>
          </div>
          <div className="col-12 tharddetailsblog mt-5">
            <h3>Abstract</h3>
            <p>
              RapidCure Sealer is 98% Volume Solids, Modified Cold Cured Epoxy,
              non-solvent type, designed for applications on substrates affected
              corroded by Aluminum Chloride Fumes, Powder, & Exhibit excellent
              adhesion to abrasive blasted surface, cast iron and stainless
              steel. Excellent water, chemical, salt, oil resistance.
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12 col-12">
            <img
              src="https://agepcoatings.com/Uploads/blog/03rapidcureSealer/01.png"
              className="img-fluid"
              alt="imgcoa"
            />
            <p>Initial Condition of Valves</p>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-12">
            <img
              src="https://agepcoatings.com/Uploads/blog/03rapidcureSealer/02.png"
              className="img-fluid"
              alt="imgcoa"
            />
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-12">
            <img
              src="https://agepcoatings.com/Uploads/blog/03rapidcureSealer/03.png"
              className="img-fluid"
              alt="imgcoa"
            />
          </div>
        </div>
      </div>
      <div className="container tharddetailsblog">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-5">
            <h3>Design Considerations</h3>
            <p>
              Following design parameters were considered while verifying
              suitability of RapidCure Sealer for the application.
            </p>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <table className="table table-striped table-bordered">
              <tbody>
                <tr>
                  <td className="text-uppercase">
                    <strong>Aluminum Chloride Handling Units</strong>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>Substrate</td>
                  <td>Carbon Steel, SS</td>
                </tr>
                <tr>
                  <td>Media</td>
                  <td>Aluminum Chloride Powder, Fumes</td>
                </tr>
                <tr>
                  <td>Design Temperature</td>
                  <td>150° C</td>
                </tr>
                <tr>
                  <td>Operating Temperature</td>
                  <td>5° - 150° C</td>
                </tr>
              </tbody>
            </table>
            <p>
              Concerning the application to be applied following parameters were
              considered.
            </p>
            <table className="table table-striped table-bordered">
              <tbody>
                <tr>
                  <td>Existing Condition</td>
                  <td>Corrosion on substrates</td>
                </tr>
                <tr>
                  <td>Suitability of Coatings</td>
                  <td>Suitable on blasted surface</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="container tharddetailsblog">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <h3>
              Additionally, the following challenges were considered while
              selection of repair system.
            </h3>
            <ul>
              <li>
                Applicability of the system in high relative humidity conditions
                at the time of application/installation (85%)
              </li>
              <li>
                Temperature fluctuations during operation, requirement of
                flexibility in the system.
              </li>
            </ul>
            <p className="mt-2">
              Following comparison table provides suitability statement for
              RapidCure ST
            </p>
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Parameter</th>
                  <th>Design Parameter</th>
                  <th>RapidCure Sealer</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Type of system</td>
                  <td>Solvent-free Epoxy Lining System</td>
                  <td>Solvent-free Epoxy Lining System</td>
                </tr>
                <tr>
                  <td>Design Temperature</td>
                  <td>150° C</td>
                  <td>150° C</td>
                </tr>
                <tr>
                  <td>Application Temperature</td>
                  <td>Ambient less than 50° C</td>
                  <td>Ambient up to 150° C</td>
                </tr>
                <tr>
                  <td>Relative Humidity Tolerance</td>
                  <td>Max 85%</td>
                  <td>Less than 85%</td>
                </tr>
                <tr>
                  <td>Thickness (NACE No. 10)</td>
                  <td>150 micron in single coat DFT</td>
                  <td>150 micron in single coat DFT</td>
                </tr>
                <tr>
                  <td>Curing schedule required taking in operation</td>
                  <td>-</td>
                  <td>Unrestricted after touch-dry</td>
                </tr>
                <tr>
                  <td>Salt Spray Test (ASTM D 177)</td>
                  <td>4000 Hours pass</td>
                  <td>
                    4200 hours Meets NORSOK M-501 requirements of Salt Spray
                    Test
                  </td>
                </tr>
                <tr>
                  <td>Primer Requirements</td>
                  <td>Should Be Self Sufficient Coating</td>
                  <td>Self Sufficient Coating to be used as Primer too.</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-12">
            <img
              src="https://agepcoatings.com/Uploads/blog/03rapidcureSealer/04.png"
              className="img-fluid"
              alt="imgcoa"
            />
            <p>Primer Application</p>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-12">
            <img
              src="https://agepcoatings.com/Uploads/blog/03rapidcureSealer/05.png"
              className="img-fluid"
              alt="imgcoa"
            />
            <p>Top Coat Application</p>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-12">
            <img
              src="https://agepcoatings.com/Uploads/blog/03rapidcureSealer/06.png"
              className="img-fluid"
              alt="imgcoa"
            />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <h3>Design of the System</h3>
            <p>
              Aluminum Chloride being hygroscopic in nature reacts with water,
              air moisture and acids are formed and corrodes the surfaces which
              comes in contact with them. So Coating System should be chemical
              resistant and doesn’t get damaged by chemical attack. System is
              designed to meet chemical resistance coatings and designed to give
              3-5 Years of Service Life in the exposed aluminum chloride fumes &
              powder & environmental conditions i.e., weather conditions,
              ambient temperature changes.
            </p>
            <h3>Procedure Followed</h3>
            <ul>
              <li>
                Surface Preparation: Abrasive Blasting to Sa 2½ with surface
                profile of 40-75 micron
              </li>
              <li>
                Primer – RapidCure Sealer to be applied as Primer up to 150
                micron DFT after maintaining minimum coating interval.
              </li>
              <li>
                Top Coat - RapidCure Sealer to be applied as Top Coat up to 150
                micron DFT
              </li>
              <li>Surface to be left for curing.</li>
              <li>Application Completed.</li>
            </ul>
            <h3>Enclosure</h3>
            <p>RapidCure EP-F Data Sheet</p>
          </div>
        </div>
      </div>
      <div className="container tharddetailsblog">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-3 d-flex">
            <img
              src="https://agepcoatings.com/Uploads/blog/03rapidcureSealer/07.png"
              className="img-fluid"
              alt="imgcoa"
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-3 d-flex">
            <img
              src="https://agepcoatings.com/Uploads/blog/03rapidcureSealer/08.png"
              className="img-fluid"
              alt="imgcoa"
            />
          </div>
        </div>
      </div>

      {/* <div className="container-fluid thardblog">
        <div className="row">
          <div className="con-content"></div>
        </div>
      </div>
      <div className="container tharddetails">
        <h3 className="mb-5">
          DESIGN CONSIDERATIONS AND SUITABILITY OF RAPIDCURE SEALER FOR <br />{" "}
          APPLICATION ON ALUMINIUM CHLORIDE HANDLING PLANTS & UNITS
        </h3>
        <div className="row">
          <div className="col-4">
            <h5>Written by</h5>
            <p>Harshwardhan Garge</p>
          </div>
          <div className="col-4">
            <h5>Published on</h5>
            <p>02th July, 2024</p>
          </div>
          <div className="col-4">
            <h5>Read time</h5>
            <p> 7 minute</p>
          </div>
          <div className="col-12 tharddetailsblog mt-5">
            <h3>Abstract</h3>
            <p>
              RapidCure Sealer is 98% Volume Solids, Modified Cold Cured Epoxy,
              non-solvent type, designed for applications on substrates affected
              corroded by Aluminum Chloride Fumes, Powder, & Exhibit excellent
              adhesion to abrasive blasted surface, cast iron and stainless
              steel. Excellent water , chemical, salt , oil resistance.
            </p>
          </div>
          <div className="col-6">
            <img
              src="https://agepcoatings.com/Uploads/blog/03rapidcureSealer/01.png"
              className="img-fluid"
              alt="imgcoa"
            />
            <p>Initial Condition of Valves</p>
          </div>
          <div className="col-6">
            <img
              src="https://agepcoatings.com/Uploads/CaseStudy/case1.png"
              className="img-fluid"
              alt="imgcoa"
            />
          </div>
        </div>
      </div>
      <div className="container tharddetailsblog">
        <div className="row">
          <div className="col-12 mt-5">
            <h3>Design Considerations</h3>
            <p>
              Following design parameters were considered while verifying
              suitability of RapidCure EP-F for the application.
            </p>
          </div>
          <div className="col-12 ">
            <table className="table table-striped table-bordered">
              <tbody>
                <tr>
                  <td>Value</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Substrate</td>
                  <td>Carbon Steel, SS</td>
                </tr>
                <tr>
                  <td>Media</td>
                  <td>Portable Drinking Water</td>
                </tr>
                <tr>
                  <td>Design Temperature</td>
                  <td>150° C</td>
                </tr>
                <tr>
                  <td>Design Temperature</td>
                  <td>5? - 150? C</td>
                </tr>
              </tbody>
            </table>
            <p>
              Concerning the application to be applied following parameters were
              considered.
            </p>
            <table className="table table-striped table-bordered">
              <tbody>
                <tr>
                  <td>Existing Condition</td>
                  <td>New Valves</td>
                </tr>
                <tr>
                  <td>Suitability of Coatings</td>
                  <td>
                    Food Grade Epoxy and Doesn’t affect the drinking water
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="container tharddetailsblog">
        <div className="row">
          <div className="col-12 mt-5">
            <h3>
              Additionally, following challenges were considered while selection
              of repair system.
            </h3>
            <ul>
              <li>
                Applicability of the system in high relative humidity conditions
                at the time of application/installation (85%)
              </li>
              <li>
                Temperature fluctuations during operation, requirement of
                flexibility in the system.
              </li>
            </ul>
            <p className="mt-2">
              Following comparison table provides suitability statement for
              RapidCure EP-F
            </p>
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th scope="col-4">Parameter</th>
                  <th scope="col-4">Design Parameter</th>
                  <th scope="col-4">RapidCure EP-F</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Type of system</td>
                  <td>-free Epoxy Lining SystemSolvent</td>
                  <td>Solvent-free Epoxy Lining System</td>
                </tr>
                <tr>
                  <td>Design Temperature</td>
                  <td>150? C</td>
                  <td>150? C</td>
                </tr>
                <tr>
                  <td>Long Term Durability Test</td>
                  <td>
                    BS:6920 vide report number 2011KL0677. Meets WRAS Schedule 2
                    Paragraph 2 & Guidance G2.2.
                  </td>
                  <td>
                    BS:6920 vide report number 2011KL0677. Meets WRAS Schedule 2
                    Paragraph 2 & Guidance G2.2.
                  </td>
                </tr>
                <tr>
                  <td>Application Temperature</td>
                  <td>Ambient 50? C</td>
                  <td>Ambient up to 150? C</td>
                </tr>
                <tr>
                  <td>Relative Humidity Tolerance</td>
                  <td>Max 85%</td>
                  <td>85%</td>
                </tr>
                <tr>
                  <td>Thickness (NACE No. 10)</td>
                  <td>100 micron in single coat DFT</td>
                  <td>100 micron in single coat DFT</td>
                </tr>
                <tr>
                  <td>Curing schedule required taking in operation</td>
                  <td>-</td>
                  <td>Unrestricted after touch-dry</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-4">
            <img
              src="https://agepcoatings.com/Uploads/CaseStudy/case1.png"
              className="img-fluid"
              alt="imgcoa"
            />
            <p>Primer Application</p>
          </div>
          <div className="col-4">
            <img
              src="http://localhost:3008/Uploads/CaseStudy/case1.png"
              className="img-fluid"
              alt="imgcoa"
            />
            <p>Top Coat Application</p>
          </div>
          <div className="col-4">
            <img
              src="http://localhost:3008/Uploads/CaseStudy/case1.png"
              className="img-fluid"
              alt="imgcoa"
            />
          </div>
          <div className="col-12">
            <h3>Design of the System</h3>
            <p>
              System is designed to meet food grade coatings and designed to
              give 3-5 Years of Service Life in the exposed environmental
              conditions i.e. weather conditions, ambient temperature changes.
            </p>
            <h3>Procedure Followed</h3>
            <ul>
              <li>
                Surface Preparation: Abrasive Blasting to Sa 2½ with surface
                profile of 40-75 micron
              </li>
              <li>
                Primer RapidCure EP-F to be applied as Primer upto 100 micron
                DFT.{" "}
              </li>
              <li>
                Top Coat - RapidCure EP-F to be applied as Top Coat upto 100
                micron DFT
              </li>
              <li>Surface to be left for curing.</li>
              <li>Application Completed.</li>
            </ul>
            <h3>Enclosure</h3>
            <p>RapidCure EP-F Data Sheet</p>
          </div>
        </div>
      </div>
      <div className="container tharddetailsblog">
        <div className="row">
          <div className="col-6">
            <img
              src="http://localhost:3008/Uploads/CaseStudy/case1.png"
              className="img-fluid"
              alt="imgcoa"
            />
          </div>
          <div className="col-6">
            <img
              src="http://localhost:3008/Uploads/CaseStudy/case1.png"
              className="img-fluid"
              alt="imgcoa"
            />
          </div>
        </div>
      </div> */}
      <Footer />
    </>
  );
};

export default ThardBlog;
