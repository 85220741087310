export const handleSearchClick = (query, products, onSearch) => {
    const keywordLower = query.toLowerCase().trim();
  
    const filteredProducts = products.filter((product) => {
      const nameMatch = product.product_name ? product.product_name.toLowerCase().includes(keywordLower) : false;
      const descriptionMatch = product.description ? product.description.toLowerCase().includes(keywordLower) : false;
      const keywordMatch = product.keywords ? product.keywords.some((key) => key.toLowerCase().includes(keywordLower)) : false;
      const categoryMatch = product.category_name ? product.category_name.toLowerCase().includes(keywordLower) : false;

      console.log(keywordMatch, categoryMatch);
  
      return nameMatch || descriptionMatch || keywordMatch || categoryMatch;
    });
  
    if (filteredProducts.length === 0) {
      onSearch(products); // Show all products if no matches
    } else {
      onSearch(filteredProducts);
    }
  };
  