import React from 'react';
import "./fivesection.css"
import Blogssection from '../../BlogesMain/Blogssection';



const Fivesection = ()=> {

  return (
    <div className="outer-container">
    <div className="container">
      <div className="header">
      <h1>BLOGS</h1>
      </div>
      <div className="row">
          <Blogssection />
      </div>
    </div>
  </div>
  );
}

export default Fivesection;
