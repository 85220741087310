import React, { useState } from "react";
import "./Proslide.css";
import { handleSearchClick } from "./searchUlits";

const Proslide = ({ products, onSearch }) => {
  const [query, setQuery] = useState("");

  const handleClick = () => {
    handleSearchClick(query, products, onSearch); // Use the imported function
  };


  // const handleSearchClick = () => {
  //   const keywordLower = query.toLowerCase().trim();

  //   const filteredProducts = products.filter((product) => {
  //     const nameMatch = product.product_name ? product.product_name.toLowerCase().includes(keywordLower) : false;
  //     const descriptionMatch = product.description ? product.description.toLowerCase().includes(keywordLower) : false;

  //     const keywordMatch = product.keywords
  //       ? product.keywords.some((key) => key.toLowerCase().includes(keywordLower))
  //       : false;

  //     const categoryMatch = product.category_name ? product.category_name.toLowerCase().includes(keywordLower) : false;

  //     return nameMatch || descriptionMatch || keywordMatch || categoryMatch;
  //   });

  //   if (filteredProducts.length === 0) {
  //     onSearch(products); 
  //   } else {
  //     onSearch(filteredProducts);
  //   }
  // };

  return (
    <div className="background">
      <div className="search-container text-center">
        <div className="input-group mb-3 border-0">
          <input
            type="text"
            className="form-control transparent-input"
            placeholder="Search Category/ Product"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            aria-label="Search"
            aria-describedby="basic-addon1"
          />
          {/* <button
            className="btn btn-primary"
            type="button"
            onClick={handleSearchClick}
          >
            Search
          </button> */}
          <span className="input-group-text" id="basic-addon1"  onClick={handleClick}>
              <i className="fas fa-search border-0"></i>
            </span>
        </div>
      </div>
    </div>
  );
};

export default Proslide;
