import React from "react";
import "./caseslider.css";
import CardsList from "./CaseStudyData/CardDetails";

const Caseslider = () => {
  return (
    <>
      <div className="container-fluid casemain">
        <div className="row">
          <div className="case-content">
            <h1>case studies</h1>
            <a href="/Contact">
              <button type="button" className="slide-button text-light">
                Contact Now
              </button>
            </a>
          </div>
        </div>
      </div>
      <CardsList />
    </>
  );
};

export default Caseslider;
