import React from "react";
import "./slider.css";
import slide1 from "../../../assets/homepage/herobanner01/slide1.png";
import slide2 from "../../../assets/homepage/herobanner01/slide2.png"
import slide3 from "../../../assets/homepage/herobanner01/slide3.png"

const Slider = () => {
  return (
    <>
      <div className="container-fluid p-0 overflow-hidden">
        <div className="row no-gutters">
          <div
            id="carouselExampleCaptions"
            className="carousel slide custom-slider"
            data-bs-ride="carousel" data-bs-interval="2000"
          >
            <div className="carousel-indicators slides-rounddot">
              <button
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
            </div>
            <div className="carousel-inner home-slider">
              <div className="carousel-item active">
                <img
                  src={slide1}
                  className="d-block w-100 img-fluid"
                  alt="First slide"
                />
              </div>
              <div className="carousel-item">
                <img
                  src={slide2}
                  className="d-block w-100 img-fluid"
                  alt="Second slide"
                />
              </div>
              <div className="carousel-item">
                <img
                  src={slide3}
                  className="d-block w-100 img-fluid"
                  alt="Third slide"
                />
              </div>
            </div>
            <div className="carousel-caption slider-content d-flex">
              <div className="text-wrapper">
                <h1 className="first-line">Protective Coatings</h1>
                <h1 className="second-line">for Toughest Conditions</h1>
                <p>Igniting a new chapter in distribution excellence!</p>
                <a href="/Contact">
                  <button type="button" className="slide-button btn btn-warning ">
                    Contact Now
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <FirstSlidessection/> */}
      {/* <Socialicon /> */}
    </>
  );
};

export default Slider;
