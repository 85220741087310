import React from 'react'
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'
import Caseslider from '../../Components/CasestudyMain/Caseslider'

const Casestudy = () => {
  return (
    <>
    <Header />
    <Caseslider />
    <Footer />
    
    </>
  )
}

export default Casestudy