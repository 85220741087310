import React from "react";
import { useLocation } from "react-router-dom";
import "./CardView.css"
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Sevensection from "../Main/SevenSection/Sevensection";
import Proimg from "../ProductMain/Productimglayout/Proimg"

const CardView = () => {
  const location = useLocation();
  console.log(location);
  const card = location?.state.card;
  return (
    <>
      <Header />
      <div className="container-fluid casemain">
        <div className="row">
          <div className="case-content">
            <h1>case studies</h1>
            <a href="/Casestudy">
              <button type="button" className="slide-button text-light">
                Know More
              </button>
            </a>
          </div>
        </div>
      </div>

      
      {/* All Details Data Get  */}
      <div className="container case-details">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            {card.details.map((detail, detailIndex) => (
              <div key={detailIndex}>
                <h5>{detail.title}</h5>

                <p>{detail.paragraph}</p>
                {detail.subDetails && (
                  <ul>
                    {detail.subDetails.map((subDetail, subDetailIndex) => (
                      <div key={subDetailIndex}>
                        <strong>{subDetail.title}</strong> {subDetail.text}
                        <p>{subDetail.paragraph}</p>
                      </div>
                      
                    ))}
                  </ul>
                )}
                {detail.listItems && (
                  <ul>
                    {detail.listItems.map((item, itemIndex) => (
                      <li key={itemIndex}>{item.text}</li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <Sevensection />
      <Proimg />
      <Footer />
    </>
  );
};

export default CardView;
