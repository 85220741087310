import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./header.css";
import logo from "../../assets/Logo/logo.png";

const Header = ({ products, onSearch }) => {
  const [value, setValue] = useState("");

  const navigate = useNavigate();

  const handleSearch = (event) => {
    event.preventDefault();

    if (value) {
      console.log(value);

      navigate("/Product", { state: value });
    }
  };

  const currentPath = window.location.pathname;

  return (
    <>
      <nav className="navbar position-absolute navbar-expand-sm navbar-dark ">
        <div className="container bg-nav">
          <a className="navbar-brand" href="/">
            <img
              src={logo}
              alt="Logo"
              className="img-fluid"
              width="100"
              height="100"
            />
          </a>

          <button
            className="navbar-toggler burger-menu"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon burger-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto responsivenav" id="responsivenav">
              <li className="nav-item">
                <a
                  className={`nav-link ${currentPath === "/" ? "active" : ""}`}
                  href="/"
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    currentPath === "/Product" ? "active" : ""
                  }`}
                  href="/Product"
                >
                  Product
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    currentPath === "/About" ? "active" : ""
                  }`}
                  href="/About"
                >
                  About
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    currentPath === "/Blog" ? "active" : ""
                  }`}
                  href="/Blog"
                >
                  Blogs
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    currentPath === "/Casestudy" ? "active" : ""
                  }`}
                  href="/Casestudy"
                >
                  Cases
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    currentPath === "/Contact" ? "active" : ""
                  }`}
                  href="/Contact"
                >
                  Contact
                </a>
              </li>
            </ul>
          </div>
          <div className="search-and-icons ms-auto">
            <form onSubmit={handleSearch}>
            <div className="d-flex search-box position-static">
              <input
                className="me-2 search-txt"
                type="search"
                placeholder="Search"
                aria-label="Search"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
              <button type="submit" className="search-btn" >
                {/* <a href="/Product" > */}
                <i className="fas fa-search"></i>
                {/* </a> */}
              </button>
            </div>
            </form>
            <a href="/Contact" className="top-btn small" id="send-btn">
              Send Query
            </a>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
