import React from "react";
import "./aboutslider.css";

const AboutSlider = () => {
  return (
    <div className="container About-slider">
      <h1 className="text-center mb-md-3 text-uppercase">Our star products</h1>

      <div className="row d-flex justify-content-center">
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-bs-ride="carousel"
          data-bs-interval="5000"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="3"
              aria-label="Slide 4"
            ></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="container-fluid slider-container">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <img
                      src="https://agepcoatings.com/Uploads/About_Rapid/01.png"
                      className="d-block w-100"
                      alt="Image1"
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 proslides-img">
                    <img
                      src="https://agepcoatings.com/Uploads/About_Rapid/rapidcure_logo/01.png"
                      alt="abt-img"
                      className=" img-fluid w-75"
                    />
                    {/* <h1 style={{ color: "#EA2B7B", fontWeight: " 600" }}>
                      Mastic
                    </h1> */}
                    <p>
                      RapidCure Mastic is a two-component solid epoxy polyam-
                      ide, high volume solid, high build, surface tolerant
                      coating complant to SSPC Paint 22, designed to work in low
                      to highly corrosive industrial environments (C5).
                      Excellent adhesion on less-prepared steel and concrete.
                      Exhibits excellent adhesion to moderately prepared
                      surface, excellent water resistance. Used as primer in a
                      multi-coat coating systems..
                    </p>
                    <a href="/Product" className="top-btn text-black p-2">
                      Explore Our Products
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container-fluid slider-container">
                <div className="row w-100">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 slider-image">
                    <img
                      src="https://agepcoatings.com/Uploads/About_Rapid/02.png"
                      className="d-block w-100"
                      alt="Image1"
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 proslides-img">
                    <img
                      src="https://agepcoatings.com/Uploads/About_Rapid/rapidcure_logo/03.png"
                      alt="abt-img"
                      className=" img-fluid w-75"
                    />
                    {/* <h1 style={{ color: "#EE473E", fontWeight: " 600" }}>ST</h1> */}
                    <p>
                      RapidCure ST is low viscosity, high build, 90% volume
                      solid epoxy polyamine coating exhibiting excellent
                      adhesion to most surfaces (steel, non- ferrous substrates
                      such as stain- less steel and aluminium, plastics, wood,
                      concrete, glass etc.) without requiring sophisticated
                      surface preparation tech- niques. RapidCure ST is designed
                      to be applied over substrates in areas where achieving
                      higher degree of surface cleanliness e.g. abrasive
                      blasting, may not be feasible. Rapid- Cure ST exhibits
                      excellent water, chemical, salt and oil resis- tance due
                      to its low permeability. Used as primer coat or as a
                      self-sufficient coating system in most cases.
                    </p>
                    <button className="top-btn text-black p-2">
                      Explore Our Products
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container-fluid slider-container">
                <div className="row w-100">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 slider-image">
                    <img
                      src="https://agepcoatings.com/Uploads/About_Rapid/03.png"
                      className="d-block w-100"
                      alt="Image1"
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 proslides-img">
                    <img
                      src="https://agepcoatings.com/Uploads/About_Rapid/rapidcure_logo/04.png"
                      alt="abt-img"
                      className=" img-fluid w-75"
                    />
                    {/* <h1 style={{ color: "#1E7CAF", fontWeight: " 600" }}>UW</h1> */}
                    <p>
                      RapidCure UW has excellent water, chemical, salt, oil
                      resistance, underwater and low temperature curing
                      capabili- ties
                    </p>
                    <button className="top-btn text-black p-2">
                      Explore Our Products
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container-fluid slider-container">
                <div className="row w-100">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 slider-image">
                    <img
                      src="https://agepcoatings.com/Uploads/About_Rapid/04.png"
                      className="d-block w-100"
                      alt="Image1"
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 proslides-img">
                    <img
                      src="https://agepcoatings.com/Uploads/About_Rapid/rapidcure_logo/02.png"
                      alt="abt-img"
                      className=" img-fluid w-75"
                    />
                    {/* <h1 style={{ color: "#A3464D", fontWeight: " 600" }}>PU</h1> */}
                    <p>
                      RapidCure PU is a low viscosity, high build, 50% volume
                      solid Ester-modified Aliphatic coating exhibiting
                      excellent adhesion to most epoxy and polyurethane primers
                      and surfacers. RapidCure PU is designed to be applied over
                      substrates in areas which are subjected to direct sunlight
                      and UV light. RapidCure PU exhibits excellent UV
                      resistance, colour and shade retention, excellent
                      resistance to humidity and oil resis- tance. Used as
                      finish coat.
                    </p>
                    <button className="top-btn text-black p-2">
                      Explore Our Products
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AboutSlider;
