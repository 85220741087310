import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Fourthsection from "../../Main/Fourthsection/Fourthsection";
import Sevensection from "../../Main/SevenSection/Sevensection";

// const baseURL = "http://localhost:3008";

const CardsList = () => {
  const navigate = useNavigate();
  const [cards, setCards] = useState([]);
  // const [selectedCard, setSelectedCard] = useState(null);

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const res = await fetch(`/gatCaseStudy`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (!res.ok) {
          throw new Error("Failed to fetch cards");
        }
        const data = await res.json();
        setCards(data.result);
      } catch (error) {
        console.error("Error fetching cards:", error);
      }
    };

    fetchCards();
  }, []);

  const handleShowDetails = (card) => {
    // setSelectedCard(card);
    navigate("/view-card", {
      state: {
        card: card,
      },
    });
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 caseheading">
          <h2>
              Discover some of the biggest cases that are coated in AGEP Coatings Pvt.ltd.
            </h2>
          </div>
          {cards.map((card, index) => (
            <div key={index} className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xm-12 mt-md-5 mb-5 text-black d-flex justify-content-center">
              <div className="card border-0 shadow-lg">
                <img
                  src={card.image}
                  alt="Cardimage"
                  className="card-img-top"
                />
                <div className="card-body text-black p-2">
                  <p className="card-text" style={{ color: "#051D71", fontWeight: " 600" }}>{card.paragraph}</p>
                    <FontAwesomeIcon className="fat-icon"
                      icon={faArrowRight}
                      onClick={() => handleShowDetails(card)}
                    />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Sevensection />
      <Fourthsection />
    </>
  );
};

export default CardsList;
