import React from "react";
import "./fourstblog.css";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";

const FourthBlog = () => {
  return (
    <>
      <Header />
      <div className="container-fluid fourthblog">
        <div className="row">
          {/* <div className="blog-content text-light">
          <h1> DESIGN CONSIDERATIONS AND SUITABILITY OF RAPIDCURE SYSTEM FOR
          APPLICATION ON COOLING TOWER STRUCTURES</h1>
          </div>
          <a href="/Blog">
              <button type="button" className="btn btn-warning">
                Know More
              </button>
            </a> */}
          <div className="blog-content text-light">
            <h1>
              DESIGN CONSIDERATIONS AND SUITABILITY OF RAPIDCURE SYSTEM FOR
              APPLICATION ON COOLING TOWER STRUCTURES
            </h1>
            <a href="/Blog">
              <button type="button" className="slide-button text-light">
                Know More
              </button>
            </a>
          </div>
        </div>
      </div>

      <div>
        <div className="container tharddetails">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xm-12 col-12">
              <h5>Written by</h5>
              <p>Harshvardhan Garge</p>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xm-12 col-12">
              <h5>Published on</h5>
              <p>02th July, 2024</p>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xm-12 col-12">
              <h5>Read time</h5>
              <p>7 minute</p>
            </div>
            <div className="col-12 tharddetailsblog mt-5">
              <h3>Abstract</h3>
              <p>
                <strong>Primer</strong> - RapidCure Sealer AL (Aluminium) is 98%
                Volume Solids, Modified Cold Cured Epoxy, non-solvent type,
                designed for applications on cooling tower structures corroded
                by chemically treated water used in cooling towers. The product
                exhibit excellent adhesion to abrasive blasted surface, cast
                iron and stainless steel. Excellent water, chemical, salt, oil
                resistance and protect surface which comes in direct /
                continuous contact with water.
                <br />
                <strong>Intermediate</strong> – RapidCure Sealer is 98% Volume
                Solids, Modified Cold Cured Epoxy, non-solvent type, designed
                for applications on cooling tower structures corroded by
                chemically treated water used in cooling towers. The product
                exhibit excellent adhesion on primers and helps in bonding
                between primer and topcoat. Gives excellent resistance on
                corroded zones, humid zones and on surface which comes in
                continuous contact with chemically treated water.
                <br />
                <strong>Top Coat</strong> - RapidCure PU 17-06 is a high build,
                70% volume solid, Ester-modified Aliphatic Polyurethane coating
                exhibiting excellent adhesion to most epoxy and polyurethane
                primers and surfacers. RapidCure PU 17-06 coating to be applied
                over substrates in areas which are subjected to direct sunlight
                and UV light. RapidCure PU 17-06 exhibits good UV resistance,
                moderate colour and shade retention, excellent resistance to
                humidity and oil resistance. When used as finish coat on
                concrete, RapidCure PU 17-06 does not allow algae growth on the
                structure.
              </p>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xm-12">
              <img
                src="https://agepcoatings.com/Uploads/blog/04rapidcureSystem/01.png"
                className="img-fluid"
                alt="imgcoa"
              />
              <p>Initial Condition of Valves</p>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xm-12">
              <img
                src="https://agepcoatings.com/Uploads/blog/04rapidcureSystem/02.png"
                className="img-fluid"
                alt="imgcoa"
              />
            </div>
          </div>
        </div>

        <div className="container tharddetailsblog">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-sm-12 col-12 mt-5">
              <h3>Design Considerations</h3>
              <p>
                Following design parameters were considered while verifying
                suitability of RapidCure System for the application.
              </p>
            </div>
            <div className="col-xl-12 col-lg-12 col-sm-12 col-12">
              <table className="table table-striped table-bordered">
                <tbody>
                  <tr>
                    <td className="text-uppercase">
                      <strong>Cooling Towers And Surrounding Areas</strong>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Substrate</td>
                    <td>Carbon Steel, MS</td>
                  </tr>
                  <tr>
                    <td>Media</td>
                    <td>Chemically Treated water</td>
                  </tr>
                  <tr>
                    <td>Design Temperature</td>
                    <td>150° C</td>
                  </tr>
                  <tr>
                    <td>Operating Temperature</td>
                    <td>5° - 150° C</td>
                  </tr>
                </tbody>
              </table>
              <p>
                Concerning the application to be applied following parameters
                were considered.
              </p>
              <table className="table table-striped table-bordered">
                <tbody>
                  <tr>
                    <td>Existing Condition</td>
                    <td>Corrosion on substrates</td>
                  </tr>
                  <tr>
                    <td>Suitability of Coatings</td>
                    <td>Suitable on power tool / hand tool cleaned surface.</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="container tharddetailsblog">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-sm-12 col-12 mt-5">
              <h3>
                Additionally, the following challenges were considered while
                selection of coating system.
              </h3>
              <ul>
                <li>
                  Applicability of the system in high relative humidity
                  conditions at the time of application/installation (85%)
                </li>
                <li>
                  Temperature fluctuations during operation, requirement of
                  flexibility in the system.
                </li>
                <li>Fast Curing of the product.</li>
                <li>Wet surface during application of coatings.</li>
              </ul>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xm-12 col-12">
              <img
                src="https://agepcoatings.com/Uploads/blog/04rapidcureSystem/03.png"
                className="img-fluid"
                alt="imgcoa"
              />
              <p>Primer Application</p>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xm-12 col-12">
              <img
                src="https://agepcoatings.com/Uploads/blog/04rapidcureSystem/04.png"
                className="img-fluid"
                alt="imgcoa"
              />
              <p>Top Coat Application</p>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xm-12 col-12">
              <img
                src="https://agepcoatings.com/Uploads/blog/04rapidcureSystem/05.png"
                className="img-fluid"
                alt="imgcoa"
              />
            </div>
            <div className="col-12">
              <h3>Design of the System</h3>
              <p>
                Water used in cooling towers is chemically treated water and is
                dosed with acids, alkalis and other chemical for re-using the
                same water in cooling tower again and again. The chemicals
                present in water comes in direct contact with cooling tower
                structures resulting in rapid and heavy corrosion formation
                resulting in deformation of metal and collapse of the
                structures.
                <br />
                So Coating System should be chemical resistant and doesn’t get
                damaged by chemical present in water.
                <br />
                System is designed to meet chemical resistance coatings and
                designed to give 5 Years of Service Life in environmental
                conditions i.e., weather conditions, ambient temperature
                changes, moisture content zones.
              </p>
              <h3>Procedure Followed</h3>
              <ol>
                <li>
                  Surface Preparation: Prepare the surface using power tools /
                  hand tool cleaning upto ST 3 standard. Surface should be free
                  from paint, rust and should give a metal shine finish with
                  surface profile of 20-30 microns.
                </li>
                <li>
                  Primer – RapidCure Sealer AL to be applied as Primer by Brush
                  up to 150 micron DFT and coating interval should be maintained
                  for intermediate coat application.
                </li>
                <li>
                  Intermediate – Rapidcure Sealer to be applied by Roller /
                  Brush up to 150 micron DFT and coating interval should be
                  maintained for Top Coat application.
                </li>
                <li>
                  Top Coat - RapidCure PU 17-06 to be applied as Top Coat by
                  Roller up to 50 micron DFT.
                </li>
                <li>Surface to be left for curing.</li>
                <li>
                  Total DFT should come to 350 microns when measured as per SSPC
                  PA 2 Standard.
                </li>
                <li>Application Completed.</li>
              </ol>
              <h3>Enclosure</h3>
              <p>
                RapidCure Sealer AL Data Sheet.
                <br />
                RapidCure Sealer Data Sheet.
                <br />
                RapidCure PU 17-06
              </p>
            </div>
          </div>
        </div>

        <div className="container tharddetailsblog">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xm-12 col-12 d-flex">
              <img
                src="https://agepcoatings.com/Uploads/blog/04rapidcureSystem/06.png"
                className="img-fluid"
                alt="imgcoa"
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xm-12 col-12 d-flex">
              <img
                src="https://agepcoatings.com/Uploads/blog/04rapidcureSystem/07.png"
                className="img-fluid"
                alt="imgcoa"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FourthBlog;
