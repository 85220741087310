import React from 'react'
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'
import ProMain from '../../Components/ProductMain/ProMain'


const Product = () => {
  return (
    <>
    <Header />
    <ProMain />
    <Footer />
    </>
  )
}

export default Product