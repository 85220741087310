import React from "react";
import "./aboutslide.css";
import Sevensection from "../../Main/SevenSection/Sevensection";
import AboutSlider from "../AboutSlider/AboutSlider";
const Aboutslide = () => {
  return (
    <>
      <div className="container-fluid aboutmain">
        <div className="row">
          <div className="abt-content">
            <h1>
              Globally known high-quality <br />
              industrial protective coatings
            </h1>
            <a href="/Contact">
              <button type="button" className="slide-button text-light">
                Contact Now
              </button>
            </a>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row about-us">
          <h1>About Us</h1>
          <p>
            <span className="fw-semibold">
              AGEP Coatings Pvt. ltd, the global leader in providing
              high-quality industrial protective coatings for corrosion control
              and management.
              <br />
            </span>
            We strive to deliver innovative solutions that exceed our customers’
            expectations and help them achieve maximum asset protection and
            longevity. With a team of experienced technocrats and a commitment
            to excellence, we aim to be the preferred choice for industries in
            need of reliable and efficient coating solutions.
          </p>
        </div>
      </div>

      <div className="container d-flex justify-content-center align-items-center">
        <div className="row w-100">
          {/* First Column */}
          <div className="col-4 d-flex justify-content-center align-items-center p-1">
            <img
              src="https://agepcoatings.com/Uploads/About_Rapid/aboutus_gallery/01.png"
              className="img-fluid"
              alt="First"
            />
          </div> 

          {/* Second Column */}
          <div className="col-4 p-1">
            <div className="half-height d-flex justify-content-center align-items-center">
              <img
                src="https://agepcoatings.com/Uploads/About_Rapid/aboutus_gallery/02.png"
                className="img-fluid"
                alt="Second"
              />
            </div>
            <div className="d-flex half-height justify-content-center align-items-center">
              <img
                src="https://agepcoatings.com/Uploads/About_Rapid/aboutus_gallery/03.png"
                className="img-fluid"
                alt="Third"
              />
            </div>
          </div>

          {/* Third Column */}
          <div className="col-4 d-flex justify-content-center align-items-center p-1">
            <img
              src="https://agepcoatings.com/Uploads/About_Rapid/aboutus_gallery/04.png"
              className="img-fluid"
              alt="Fourth"
            />
          </div>
        </div>
      </div>
      <div className="container d-flex justify-content-center align-content-center">
        <div className="row">
          <div className=" col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xm-12 col-12 mt-4">
            <a href="/Product" className="top-btn text-black p-2">
              Learn More
            </a>
          </div>
        </div>
      </div>
      <Sevensection />
      <AboutSlider />
    </>
  );
};

export default Aboutslide;
