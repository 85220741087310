import React from 'react'
// import Slider from '../SliderSection/Slider'
import Secoendscetion from './Secoendhomesection/Secoendscetion'
import Thardsection from './ThardSection/Thardsection'
import Slider from './SliderSection/Slider'
import Fourthsection from './Fourthsection/Fourthsection'
import Fivesection from './FiveSection/Fivesection'
import Sexsection from './SixSection/Sexsection'
import Sevensection from './SevenSection/Sevensection'
import EightSection from './EightSection/EightSection'


const MainHome = () => {
  return (
    <>
    <Slider />
    <Secoendscetion />
    <Thardsection />
    <Fourthsection />
    <Fivesection />
    <Sexsection />
    <Sevensection />
    <EightSection />
    
    </>
  )
}

export default MainHome