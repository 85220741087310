import React from "react";
import "./thardsectuion.css";
import brand1 from "../../../assets/TrustedBy/01.png";
import brand2 from "../../../assets/TrustedBy/02.png";
import brand3 from "../../../assets/TrustedBy/03.png";
import brand4 from "../../../assets/TrustedBy/04.png";
import brand5 from "../../../assets/TrustedBy/05.png";

const Thardsection = () => {
  return (
    <>
     

<div className="container-fluid full-screen mb-md-5 mt-md-5">
    <div className="row text-center">
        <h1 className="text-uppercase">Trusted By</h1>
        <div className="col-12 brand-images">
            <div className="row justify-content-center">
                <div className="col-md-2 col-12 p-2">
                    <img src={brand1} alt="Brand 1" className="img-fluid w-75" />
                </div>
                <div className="col-md-2 col-12 p-2">
                    <img src={brand5} alt="Brand 2" className="img-fluid w-75" />
                </div>
                <div className="col-md-2 col-12 p-2">
                    <img src={brand2} alt="Brand 3" className="img-fluid w-75" />
                </div>
                <div className="col-md-2 col-12 p-2">
                    <img src={brand4} alt="Brand 4" className="img-fluid w-75" />
                </div>
                <div className="col-md-2 col-12 p-2">
                    <img src={brand3} alt="Brand 5" className="img-fluid w-75" />
                </div>
            </div>
        </div>
        <p className="disclaimer text-secondary mt-md-5" style={{fontSize:"12px"}}>The logos has been used for representation purpose only.</p>

    </div>
</div>


    </>
  );
};

export default Thardsection;
