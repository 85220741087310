import React from "react";
import sec6 from "../../../assets/homepage/05.png";
import "./sixsection.css";

const Sexsection = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className=" col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xm-12 col-12 text-center con-heading">
            <h1>Welcome to AGEP COATINGS INDIA PVT.LTD</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xm-12 image-column">
            {/* <!-- Image Column --> */}
            <img src={sec6} className="img-fluid" alt="_coating" />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xm-12">
            {/* <!-- Content Column --> */}
            <div className="content-column">
              <h2 className="text-six">
                AGEP Coatings, the global leader in providing high-quality
                industrial protective coatings for corrosion control and
                reliability management.
              </h2>
              <p>
                We are dedicated to providing innovative solutions that not only
                meet but exceed our customers' expectations, ensuring maximum
                asset protection and longevity. Our team of experienced
                technocrats is committed to excellence, delivering reliable and
                efficient coating solutions.
                Our goal is to become the preferred choice for industries
                seeking top-quality, dependable coating services, driven by our
                unwavering focus on customer satisfaction.{" "}
              </p>
              <a href="/About" className="top-btn text-black p-2 btn-six">
                Know More
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sexsection;
