import React, { useEffect, useState } from "react";
import "./blogsection.css";
// import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

// const baseURL = "http://localhost:3008";

const Blogssection = () => {
  // const navigate = useNavigate();

  const [cardbolg, setCardBlog] = useState([]);

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const res = await fetch(`/gatBlogPage`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (!res.ok) {
          throw new Error("Failed to fetch Blog");
        }
        const data = await res.json();
        setCardBlog(data.result);
      } catch (error) {
        console.error("Error fetching Blog:", error);
      }
    };

    fetchCards();
  }, []);

  const handleClick = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <>
      {/*  Get Blog Data  */}

      <div className="container case-details">
        <div className="row">
          {cardbolg.map((blog, index) => (
            <div
              key={index}
              className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xm-12 mt-3 mb-3" // Adjusted margins
            >
              <div className="card border-0 h-100 shadow-lg bg-light rounded mx-2">
                <img
                  src={blog.image}
                  alt="Cardimage"
                  className="card-img-top"
                />
                <div className="card-body text-black p-2">
                <h5 className="card-text text-uppercase">{blog.heading}</h5>
                  <p>{blog.author}</p>
                  <p>
                    <b>Date:</b> {blog.date}
                  </p>
                  <FontAwesomeIcon
                    className="fat-icon"
                    icon={faArrowRight}
                    onClick={() => handleClick(blog.url)} // Pass the blog's URL to handleClick
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Blogssection;
