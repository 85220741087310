import React from "react";
import { useLocation } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const ViewBlog = () => {
  const location = useLocation();
  const blog = location?.state?.blog;

  if (!blog) {
    return <div>Error: Blog details not found</div>;
  }

  return (
    <>
      <Header />
      <div className="container-fluid casemain">
        <div className="row">
          <div className="case-content">
            <h1>Blog</h1>
            <button className="case-button mt-3">Know More</button>
          </div>
        </div>
      </div>

      {/* Blog Details */}
      <div className="container case-details">
        <h2>{blog.heading}</h2>
        <div className="row">
          <div className="col-md-4">
            <h4>Written By</h4>
            <p>{blog.title}</p>
          </div>
          <div className="col-md-4">
            <h4>Published On</h4>
            <p>{blog.date}</p>
          </div>
          <div className="col-md-4">
            <h4>Time</h4>
            <p>{blog.time}</p>
          </div>
          <div className="row">
            <div className="col-6 mb-5">
            <img src={blog.image} alt={blog.title} className="img-fluid"/>
            </div>
            <div className="col-6">
            <img src={blog.image} alt={blog.title} className="img-fluid"/>

            </div>

          </div>

          <div className="col-md-12">
            <div>
              <img src={blog.image} alt={blog.title} />
              <h5>{blog?.title}</h5>
              <p>{blog.content}</p>
              <p>{blog?.paragraph}</p>
              <p>{blog.paragraphnext}</p>
              <p>{blog.paragraphthree}</p>
              <h6>{blog.headingdown}</h6>
              <p>{blog.paralast}</p>
              <ul>
                <li>{blog.text1}</li>
                <li>{blog.text2}</li>
                <li>{blog.text3}</li>
                <li>{blog.text4}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ViewBlog;
