import React from 'react'
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'
import MainHome from '../../Components/Main/Main'
const Home = () => {
  return (
    <>
    <Header />
    <MainHome />
    <Footer />
    </>
  )
}

export default Home;