import React, { useEffect, useState, useRef } from "react";
import Proslide from "../Profirstlide/Proslide";
import { useLocation } from "react-router-dom";
import "./Productget.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowRight,
  faWindowClose,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";

// const baseURL = "http://localhost:3008";

const ProductGet = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [searchStateData, setSearchStateData] = useState([]);
  const { state } = useLocation();
  const productSectionRef = useRef(null);
  

  const fetchData = async () => {
    try {
      const res = await fetch(`/getAllProduct`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!res.ok) {
        throw new Error("Something went wrong!");
      }

      const data = await res.json();
      // console.log(data);
      setCategories(data.result);
      const allProducts = data.result.flatMap((category) => category.products);
      setFilteredProducts(allProducts);
      handleStateFilter(allProducts);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (searchStateData.length && productSectionRef.current) {
      productSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [searchStateData]);

  const handleAllProductsClick = () => {
    setSelectedCategory(null);
    setSelectedProduct(null);
    setFilteredProducts(categories.flatMap((category) => category.products));
    setExpandedCategory(null);
  };

  const handleCategoryClick = (category) => {
    if (expandedCategory === category) {
      setExpandedCategory(null);
    } else {
      setExpandedCategory(category);
    }
    setSelectedCategory(category);
    setSelectedProduct(null);
    setFilteredProducts(category.products);
  };

  const handleProductNameClick = (product) => {
    setSelectedProduct((prevSelectedProduct) =>
      prevSelectedProduct === product ? null : product
    );
  };

  const handleProImgClick = (product) => {
    setSelectedProduct(product);
  };

  const handleCardClick = () => {
    setSelectedProduct(null);
  };

  const handleSearch = (filteredProducts) => {
    setFilteredProducts(filteredProducts);
    setSelectedCategory(null);
    setSelectedProduct(null);
    setSearchStateData(filteredProducts);
  };

  const getProductsToDisplay = () => {
    if (selectedProduct) {
      return [selectedProduct];
    }
    if (searchStateData.length) {
      return searchStateData;
    }
    if (selectedCategory) {
      return selectedCategory.products;
    }
    return filteredProducts;
  };

  const handleStateFilter = async (data) => {
    
    if (state) {   

      const filters = data.filter((product) => {
        const nameMatch = product.product_name?.toLowerCase().includes(state.toLowerCase());
        const descriptionMatch = product.description?.toLowerCase().includes(state.toLowerCase());
        const keywordMatch = product.keywords?.some((key) => key.toLowerCase().includes(state.toLowerCase()));
        const categoryMatch = product.category_name?.toLowerCase().includes(state.toLowerCase());
       
     
  
      
        return nameMatch || descriptionMatch || keywordMatch || categoryMatch;
      });
      
      setSearchStateData(filters);
      setFilteredProducts(filters);
    }
  };

  // const handleClick = () => {
  //   const newWindow = window.open("/StoShare", "_blank", "noopener,noreferrer");
  //   if (newWindow) newWindow.opener = null;
  // };

  return (
    <>
      <Proslide
        products={categories.flatMap((category) => category.products)}
        onSearch={handleSearch}
        searchValue={state}
      />
      <div className="container">
        <div className="row pro-main">
          {/* Left side: Categories Accordion */}
          <div className="col-lg-4 col-md-4 col-sm-6 col-6 left-sidebar">
            {/* Adjusted for small screens */}
            <div className="accordion border-0 bg-accordionleft" id="accordionExample">
              <div className="accordion-item">
                <h6 className="accordion-header" id="headingAllProducts">
                  <button
                    className="accordion-button details-accordionbutton"
                    type="button"
                    onClick={handleAllProductsClick}
                  >
                    All Products
                  </button>
                </h6>
              </div>
              {categories.map((category, index) => (
                <div className="accordion-item details-accordionitem" key={index}>
                  <p className="accordion-header" id={`heading${index}`}>
                    <button
                      className="accordion-button"
                      type="button" 
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${index}`}
                      aria-expanded={expandedCategory === category}
                      aria-controls={`collapse${index}`}
                      onClick={() => handleCategoryClick(category)}
                    >
                      <FontAwesomeIcon
                        icon={expandedCategory === category ? faArrowDown : faArrowRight}
                        className="accordion-icon"
                      />
                      {category.category_name}
                    </button>
                  </p>
                  <div
                    id={`collapse${index}`}
                    className={`accordion-collapse collapse ${
                      expandedCategory === category ? "show" : ""
                    }`}
                    aria-labelledby={`heading${index}`}
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body details-accordionbody ">
                      {category.products.length > 0 ? (
                        <ul>
                          {category.products.map((product, productIndex) => (
                            <li
                              key={productIndex}
                              onClick={() => handleProductNameClick(product)}
                            >
                              <p>
                                {product.product_name} {product.description}
                              </p>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p>No products available under this category.</p>
                      )}
                    </div>
                  </div>
                </div>
              ))}
              {/* <a
                href="/"
                className="p-2 ms-2 text-danger text-decoration-none"
                onClick={handleClick}
              >
                <span className="text-first">SHIP</span>{" "}
                <span className="text-middle">2</span>
                <span className="text-two"> SHORE</span>
              </a> */}
            </div>
          </div>
          {/* Right side: Related Products as Cards */}
          <div
            className="col-lg-8 col-md-8 col-sm-6 col-6 overflow-hidden right-content"
            ref={productSectionRef}
          >
            {/* Adjusted for small screens */}
            <h1>
              {searchStateData.length
                ? "Search Results"
                : selectedCategory
                ? selectedCategory.category_name
                : "All Products"}
            </h1>
            <div className="row">
              {getProductsToDisplay().map((product, productIndex) => (
                <div key={productIndex} className="col-lg-4 col-md-6 col-sm-12 col-xm-12 col-12 mb-0">
                  {/* Adjusted column widths */}
                  <div className="card border-0 overflow-hidden">
                    <div className="card-body">
                      {product.pro_img && (
                        <img
                          src={product.pro_img}
                          alt={product.product_name}
                          className="img-fluid"
                          onClick={() => handleProImgClick(product)} // Clicking pro_img selects the product
                          style={{ cursor: "pointer" }} // Add cursor pointer for visual indication
                        />
                      )}
                      {selectedProduct &&
                        selectedProduct.product_name ===
                          product.product_name && (
                          <>
                            {product.images.map((image, imageIndex) => (
                              <div
                                key={imageIndex}
                                className="position-relative"
                              >
                                <img
                                  src={image}
                                  alt={product.product_name}
                                  className="img-fluid"
                                />
                              </div>
                            ))}
                            <FontAwesomeIcon
                              icon={faWindowClose}
                              className="btn-close-icon"
                              onClick={handleCardClick}
                            />
                          </>
                        )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <a
        href="https://agepcoatings.com/Uploads/brochure/agepcoatings_brochure.pdf"
        target="_balnk"
        download
        className="download-icon"
      >
        <FontAwesomeIcon icon={faDownload} />
      </a>
    </>
  );
};

export default ProductGet;
