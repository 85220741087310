import React from 'react'
import ProductGet from './Productsub/ProductGet'
import Sevensection from '../Main/SevenSection/Sevensection'
import Proimg from "../../Components/ProductMain/Productimglayout/Proimg"

const ProMain = () => {
  return (
    <>
    <ProductGet />
    <Sevensection />
    <Proimg />

    </>
  )
}

export default ProMain