import React from 'react'
import "./contactus.css";
import Contactfrom from './ContactForm/Contactfrom';

const ContactSlider = () => {
  return (
    <>
    <div className="container-fluid contactmain">
        <div className="row">
          <div className="con-content">
            <h1>OUR Team Of Experts</h1>
            <h2>Are here To Help you</h2>
          </div>
        </div>
      </div>
      <Contactfrom />
    
    </>
  )
}

export default ContactSlider