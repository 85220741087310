import React from 'react';
import './CardSlider.css';

const CardSlider = () => {
  const images = [
    { src: "https://agepcoatings.com/Uploads/Proslide_Card/01.png", label: "MARINE" },
    { src: "https://agepcoatings.com/Uploads/Proslide_Card/02.png", label: "OIL&GAS" },
    { src: "https://agepcoatings.com/Uploads/Proslide_Card/03.png", label: "CHEMICAL PROCESS" },
    { src: "https://agepcoatings.com/Uploads/Proslide_Card/04.png", label: "CEMENT" },
    { src: "https://agepcoatings.com/Uploads/Proslide_Card/05.png", label: "HEAVY ENGINNERING" },
    { src: "https://agepcoatings.com/Uploads/Proslide_Card/06.png", label: "COMMERCIAL SPACES" },
    { src: "https://agepcoatings.com/Uploads/Proslide_Card/07.png", label: "STEEL" },
    { src: "https://agepcoatings.com/Uploads/Proslide_Card/08.png", label: "POWER" },
  ];

  const chunkedImages = [];
  for (let i = 0; i < images.length; i += 2) {
    chunkedImages.push(images.slice(i, i + 2));
  }

  return (
    <div className="container-fluid d-block d-md-none proimgmobile">
      <div className="row">
        <h1 className="text-center">Our Project</h1>
        <div id="carouselExampleControls" className="carousel carousel-dark slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            {chunkedImages.map((imageSet, index) => (
              <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                <div className="card-wrapper d-flex justify-content-around">
                  {imageSet.map((image, idx) => (
                    <div key={idx} className="card position-relative">
                      <img src={image.src} className="card-img-top" alt={image.label} />
                      <div className="overlay">
                        <div className="text">{image.label}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CardSlider;
