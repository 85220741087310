// import React, { useState } from "react";
import React from "react";
import "./contactfrom.css";
import SubcontactForm from "./SubcontactForm";
// const baseURL = "http://localhost:3008";

const Contactfrom = () => {
  // const [formData, setFormData] = useState({
  //   name: "",
  //   email: "",
  //   message: "",
  // });

  // const handleChange = (e) => {
  //   setFormData({ ...formData, [e.target.id]: e.target.value });
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   try {
  //     const response = await fetch(`/submit-form`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(formData),
  //     });

  //     if (response.ok) {
  //       alert("Form submitted successfully!");
  //       setFormData({ name: "", email: "", message: "" });
  //     } else {
  //       alert("Failed to submit form.");
  //     }
  //   } catch (error) {
  //     console.error("Error submitting form:", error);
  //     alert("Server error. Please try again later.");
  //   }
  // };
  return (
    <>
      <div className="container contact-us">
        <div className="row">
          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-5 text-left contact-heading">
            <h1>Contact Us</h1>
          </div>
        </div>
      </div>

      <div className="container contact-con">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <h3>Registered Office</h3>
            <p>
              <strong>Thane:</strong>
            </p>
            <p>
              C/12, Godavari, Laxmi Industrial Premise, Vartak Nagar,
              <br /> Thane, Maharashtra 40060
            </p>
            <p>
              <strong>Email:</strong> coatings@agep-international.com
            </p>
            <p>
              <strong>Call:</strong> +91 7620855902
            </p>
            <p>
              <strong>Open Hours:</strong> Mon-Sat: 10AM - 06PM
            </p>
          </div>
        
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" id="gobal-form">

            <h3>Have Questions?</h3>
            <p>
              If you have any questions, please fill out the form below and
              we'll get back to you as soon as possible.
            </p>
            {/* <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder=" Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <textarea
                  className="form-control"
                  id="message"
                  placeholder="Message"
                  rows="4"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <button type="submit" className="slide-button btn text-light">
                Send
              </button>
            </form> */}
            <SubcontactForm />
          </div>

        </div>
      </div>

      <div className="container-fluid">
        <div className="row mb-5">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="map-container">
              <iframe
                title="agepcoating"
                id="gmap_canvas"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d270.7728120257952!2d72.96090329153223!3d19.210625518670117!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b9a4f5a98979%3A0xf64bdcff1cb6c962!2sAGEP%20INDIA%20PVT.%20LTD.!5e0!3m2!1sen!2sin!4v1718864016998!5m2!1sen!2sin"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contactfrom;
