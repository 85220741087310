import React from "react";
import "./firstblog.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const FirstBlog = () => {
  return (
    <>
      <Header />
      <div className="container-fluid firstblog">
        <div className="row">
          <div className="blog-content text-light">
            <h1>
              Design Considerations and suitability of RapidCure EP-F (Food
              Grade Epoxy) for Applications on Valves
            </h1>
            <a href="/Blog">
              <button type="button" className="slide-button text-light">
              Know More
              </button>
            </a>
          </div> 
        </div>
      </div>
      <div className="container firstdetails">
        {/* <h3 className="mb-5">
         <br />
          
        </h3> */}
        <div className="row">
          <div className="col-md-4 col-12 mb-3">
            <h5>Written by</h5>
            <p>Harshvardhan Garge</p>
          </div>
          <div className="col-md-4 col-12 mb-3">
            <h5>Published on</h5>
            <p>02th July, 2024</p>
          </div>
          <div className="col-md-4 col-12 mb-3">
            <h5>Read time</h5>
            <p>7 minutes</p>
          </div>
          <div className="col-12 firstdetailsblog mt-5">
            <h3>Abstract</h3>
            <p>
              RapidCure EP-F is low viscosity, non-solvent type, high volume
              solid epoxy polyamine coating designed for contact with fresh
              water. RapidCure EP-F meets the design requirements of BS:6920
              vide report number 2011KL0677. Meets WRAS Schedule 2 Paragraph 2 &
              Guidance G2.2. Exhibit excellent adhesion to abrasive blasted
              surface, cast iron, and stainless steel. Excellent water,
              chemical, salt, and oil resistance.
            </p>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img
              src="https://agepcoatings.com/Uploads/blog/01rapidcureEP-F/01.png"
              className="img-fluid"
              alt="imgcoa"
            />
            <p>Initial Condition of Valves</p>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img
              src="https://agepcoatings.com/Uploads/blog/01rapidcureEP-F/02.png"
              className="img-fluid"
              alt="imgcoa"
            />
          </div>
        </div>
      </div>
      <div className="container firstdetailsblog">
        <div className="row">
          <div className="col-12 mt-5">
            <h3>Design Considerations</h3>
            <p>
              Following design parameters were considered while verifying
              suitability of RapidCure EP-F for the application.
            </p>
          </div>
          <div className="col-12">
            <table className="table table-striped table-bordered">
              <tbody>
                <tr>
                  <td>Value</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Substrate</td>
                  <td>Carbon Steel, SS</td>
                </tr>
                <tr>
                  <td>Media</td>
                  <td>Portable Drinking Water</td>
                </tr>
                <tr>
                  <td>Design Temperature</td>
                  <td>150°C</td>
                </tr>
                <tr>
                  <td>Design Temperature</td>
                  <td>5°C - 150°C</td>
                </tr>
              </tbody>
            </table>
            <p>
              Concerning the application to be applied following parameters were
              considered.
            </p>
            <table className="table table-striped table-bordered">
              <tbody>
                <tr>
                  <td>Existing Condition</td>
                  <td>New Valves</td>
                </tr>
                <tr>
                  <td>Suitability of Coatings</td>
                  <td>
                    Food Grade Epoxy and doesn’t affect the drinking water
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="container firstdetailsblog">
        <div className="row">
          <div className="col-12 mt-5">
            <h3>
              Additionally, the following challenges were considered while
              selection of repair system.
            </h3>
            <ul>
              <li>
                Applicability of the system in high relative humidity conditions
                at the time of application/installation (85%)
              </li>
              <li>
                Temperature fluctuations during operation, requirement of
                flexibility in the system.
              </li>
            </ul>
            <p className="mt-2">
              Following comparison table provides suitability statement for
              RapidCure EP-F
            </p>
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th scope="col-4">Parameter</th>
                  <th scope="col-4">Design Parameter</th>
                  <th scope="col-4">RapidCure EP-F</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Type of system</td>
                  <td>Solvent-free Epoxy Lining System</td>
                  <td>Solvent-free Epoxy Lining System</td>
                </tr>
                <tr>
                  <td>Design Temperature</td>
                  <td>150°C</td>
                  <td>150°C</td>
                </tr>
                <tr>
                  <td>Long Term Durability Test</td>
                  <td>
                    BS:6920 vide report number 2011KL0677. Meets WRAS Schedule 2
                    Paragraph 2 & Guidance G2.2.
                  </td>
                  <td>
                    BS:6920 vide report number 2011KL0677. Meets WRAS Schedule 2
                    Paragraph 2 & Guidance G2.2.
                  </td>
                </tr>
                <tr>
                  <td>Application Temperature</td>
                  <td>Ambient up to 50°C</td>
                  <td>Ambient up to 150°C</td>
                </tr>
                <tr>
                  <td>Relative Humidity Tolerance</td>
                  <td>Max 85%</td>
                  <td>85%</td>
                </tr>
                <tr>
                  <td>Thickness (NACE No. 10)</td>
                  <td>100 micron in single coat DFT</td>
                  <td>100 micron in single coat DFT</td>
                </tr>
                <tr>
                  <td>Curing schedule required taking in operation</td>
                  <td>-</td>
                  <td>Unrestricted after touch-dry</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-4 col-12 mb-3">
            <img
              src="https://agepcoatings.com/Uploads/blog/01rapidcureEP-F/03.png"
              className="img-fluid"
              alt="imgcoa"
            />
            <p>Primer Application</p>
          </div>
          <div className="col-md-4 col-12 mb-3">
            <img
              src="https://agepcoatings.com/Uploads/blog/01rapidcureEP-F/04.png"
              className="img-fluid"
              alt="imgcoa"
            />
            <p>Top Coat Application</p>
          </div>
          <div className="col-md-4 col-12 mb-3">
            <img
              src="https://agepcoatings.com/Uploads/blog/01rapidcureEP-F/05.png"
              className="img-fluid"
              alt="imgcoa"
            />
          </div>
          <div className="col-12">
            <h3>Design of the System</h3>
            <p>
              The system is designed to meet food-grade coatings and designed to
              give 3-5 Years of Service Life in the exposed environmental
              conditions i.e. weather conditions, ambient temperature changes.
            </p>
            <h3>Procedure Followed</h3>
            <ul>
              <li>
                Surface Preparation: Abrasive Blasting to Sa 2½ with surface
                profile of 40-75 micron
              </li>
              <li>
                Primer RapidCure EP-F to be applied as Primer up to 100 micron
                DFT.
              </li>
              <li>
                Top Coat - RapidCure EP-F to be applied as Top Coat up to 100
                micron DFT
              </li>
              <li>Surface to be left for curing.</li>
              <li>Application Completed.</li>
            </ul>
            <h3>Enclosure</h3>
            <p>RapidCure EP-F Data Sheet</p>
          </div>
        </div>
      </div>
      <div className="container firstdetailsblog">
        <div className="row ">
          <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-3 d-flex">
            <img
              src="https://agepcoatings.com/Uploads/blog/01rapidcureEP-F/01.png"
              className="img-fluid"
              alt="imgcoa"
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-3 d-flex">
            <img
              src="https://agepcoatings.com/Uploads/blog/01rapidcureEP-F/05.png"
              className="img-fluid"
              alt="imgcoa"
            />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default FirstBlog;
