import React from "react";
import "./secoendblog.css";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";

const SecoendBlog = () => {
  return (
    <>
      <Header />
      <div className="container-fluid secoendblog">
        <div className="row">
          {/* <div className="blog-content text-light">
          <h1> DESIGN CONSIDERATIONS AND SUITABILITY OF RAPIDCURE ST 
          FOR EXTERNAL APPLICATION ON ACID STORAGE TANKS</h1>
          </div>
          <a href="/Blog">
              <button type="button" className="btn btn-warning">
                Know More
              </button>
            </a> */}

          <div className="blog-content text-light">
            <h1>
              DESIGN CONSIDERATIONS AND SUITABILITY OF RAPIDCURE ST FOR EXTERNAL
              APPLICATION ON ACID STORAGE TANKS
            </h1>
            <a href="/Blog">
              <button type="button" className="slide-button text-light">
                Know More
              </button>
            </a>
          </div>
        </div>
      </div>

      <div className="container secoenddetailsblog">
        <div className="row">
          <div className="col-md-4 col-sm-6 col-12 mb-3">
            <h5>Written by</h5>
            <p>Harshvardhan Garge</p>
          </div>
          <div className="col-md-4 col-sm-6 col-12 mb-3">
            <h5>Published on</h5>
            <p>02th July, 2024</p>
          </div>
          <div className="col-md-4 col-sm-6 col-12 mb-3">
            <h5>Read time</h5>
            <p>7 minutes</p>
          </div>
          <div className="col-12 secoenddetailsblog mt-5">
            <h3>Abstract</h3>
            <p>
              RapidCure ST is 90% Volume Solids, Modified Cold Cured Epoxy,
              non-solvent type, designed for applications on substrates affected
              corroded by Acid Fumes & Exhibit excellent adhesion to abrasive
              blasted surface, cast iron and stainless steel. Excellent water,
              chemical, salt, oil resistance.
            </p>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3">
            <img
              src="https://agepcoatings.com/Uploads/blog/02rapidcureST/01.png"
              className="img-fluid"
              alt="imgcoa"
            />
            <p>Before Application</p>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3">
            <img
              src="https://agepcoatings.com/Uploads/blog/02rapidcureST/02.png"
              className="img-fluid"
              alt="imgcoa"
            />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3">
            <img
              src="https://agepcoatings.com/Uploads/blog/02rapidcureST/03.png"
              className="img-fluid"
              alt="imgcoa"
            />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3">
            <img
              src="https://agepcoatings.com/Uploads/blog/02rapidcureST/04.png"
              className="img-fluid"
              alt="imgcoa"
            />
          </div>
        </div>
        <div className="container secoenddetailsblog">
          <div className="row">
            <div className="col-12 mt-5">
              <h3>Design Considerations</h3>
              <p>
                Following design parameters were considered while verifying
                suitability of RapidCure Sealer for the application.
              </p>
            </div>
            <div className="col-12">
              <table className="table table-striped table-bordered">
                <tbody>
                  <tr>
                    <td>ACID STORAGE TANKS EXTERNAL</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Substrate</td>
                    <td>Carbon Steel, MS</td>
                  </tr>
                  <tr>
                    <td>Media</td>
                    <td>Acid Fumes</td>
                  </tr>
                  <tr>
                    <td>Design Temperature</td>
                    <td>150°C</td>
                  </tr>
                  <tr>
                    <td>Operating Temperature</td>
                    <td>5°C - 150°C</td>
                  </tr>
                </tbody>
              </table>
              <p>
                Concerning the application to be applied, the following
                parameters were considered.
              </p>
              <table className="table table-striped table-bordered">
                <tbody>
                  <tr>
                    <td>Existing Condition</td>
                    <td>Corrosion on substrates</td>
                  </tr>
                  <tr>
                    <td>Suitability of Coatings</td>
                    <td>
                      Suitable on blasted surface and Power Tool Prepared
                      surface up to ST 3 as per ISO 8501-1:2007
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="container secoenddetailsblog">
          <div className="row">
            <div className="col-12 mt-5">
              <h3>
                Additionally, the following challenges were considered while
                selecting the repair system.
              </h3>
              <ul>
                <li>
                  Applicability of the system in high relative humidity
                  conditions at the time of application/installation (85%)
                </li>
                <li>
                  Temperature fluctuations during operation, requirement of
                  flexibility in the system.
                </li>
                <li>Continuous Acid Fumes in the environment</li>
              </ul>
              <p className="mt-2">
                The following comparison table provides a suitability statement
                for RapidCure ST
              </p>
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th scope="col-4">Parameter</th>
                    <th scope="col-4">Design Parameter</th>
                    <th scope="col-4">RapidCure ST</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Type of system</td>
                    <td>Solvent-free Epoxy Lining System</td>
                    <td>Solvent-free Epoxy Lining System</td>
                  </tr>
                  <tr>
                    <td>Design Temperature</td>
                    <td>150°C</td>
                    <td>150°C</td>
                  </tr>
                  <tr>
                    <td>Application Temperature</td>
                    <td>Ambient less than 50°C</td>
                    <td>Ambient up to 150°C</td>
                  </tr>
                  <tr>
                    <td>Relative Humidity Tolerance</td>
                    <td>Max 95%</td>
                    <td>Less than 95%</td>
                  </tr>
                  <tr>
                    <td>Thickness (NACE No. 10)</td>
                    <td>150 micron in single coat DFT</td>
                    <td>150 micron in single coat DFT</td>
                  </tr>
                  <tr>
                    <td>Curing schedule required taking in operation</td>
                    <td>-</td>
                    <td>Unrestricted after touch-dry</td>
                  </tr>
                  <tr>
                    <td>Salt Spray Test (ASTM D 177)</td>
                    <td>5000 Hours pass</td>
                    <td>
                      5000 hours Meets NORSOK M-501 requirements of Salt Spray
                      Test
                    </td>
                  </tr>
                  <tr>
                    <td>Primer Requirements</td>
                    <td>Should Be Self Sufficient Coating</td>
                    <td>Self Sufficient Coating to be used as Primer too.</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-3">
              <img
                src="https://agepcoatings.com/Uploads/blog/02rapidcureST/05.png"
                className="img-fluid"
                alt="imgcoa"
              />
              <p>During Application</p>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-3">
              <img
                src="https://agepcoatings.com/Uploads/blog/02rapidcureST/06.png"
                className="img-fluid"
                alt="imgcoa"
              />
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-3">
              <img
                src="https://agepcoatings.com/Uploads/blog/02rapidcureST/07.png"
                className="img-fluid"
                alt="imgcoa"
              />
            </div>
            <div className="col-12">
              <h3>Design of the System</h3>
              <p>
                Acid Fumes corrode the surfaces which come in contact with them
                as acid fumes get stuck on the substrate surface and penetrate
                inside the steel by weakening the protective paint film layer
                and advancing to corrosion of metal. So Coating System should be
                chemical resistant and doesn’t get damaged by chemical attack.
                The system is designed to meet chemical resistance coatings and
                designed to give 3-5 years of service life in the exposed acid
                fumes and other environmental conditions i.e., weather
                conditions, ambient temperature changes.
              </p>
              <h3>Procedure Followed</h3>
              <ul>
                <li>
                  Surface Preparation: Abrasive Blasting to Sa 2½ with surface
                  profile of 40-75 micron
                </li>
                <li>
                  Surface Preparation: Abrasive Blasting to Sa 2½ with surface
                  profile of 40-75 micron, if blasting is not possible, then
                  surface preparation to ST 3 Standard as per ISO 8501-1:2007
                </li>
                <li>
                  Primer – RapidCure ST to be applied as Primer upto 150 micron
                  DFT.
                </li>
                <li>
                  Top Coat - RapidCure ST to be applied as Top Coat upto 150
                  micron DFT after maintaining proper mentioned coating interval
                  as per TDS.
                </li>
                <li>Surface to be left for curing.</li>
                <li>Application Completed.</li>
              </ul>
              <h3>Enclosure</h3>
              <p>RapidCure ST Data Sheet.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="container firstdetailsblog">
        <div className="row">
          <div className="col-md-6 col-12 mb-3">
            <img
              src="https://agepcoatings.com/Uploads/blog/02rapidcureST/08.png"
              className="img-fluid"
              alt="imgcoa"
            />
            <p>Before Coating</p>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img
              src="https://agepcoatings.com/Uploads/blog/02rapidcureST/09.png"
              className="img-fluid"
              alt="imgcoa"
            />
            <p>After Coating</p>
          </div>
        </div>
      </div>

      {/* <div className="container secoenddetailsblog">
        <h3 className="mb-5">
          DESIGN CONSIDERATIONS AND SUITABILITY OF RAPIDCURE ST <br />
          FOR EXTERNAL APPLICATION ON ACID STORAGE TANKS
        </h3>
        <div className="row">
          <div className="col-4">
            <h5>Written by</h5>
            <p>Lalit Chaudhari</p>
          </div>
          <div className="col-4">
            <h5>Published on</h5>
            <p>02th July, 2024</p>
          </div>
          <div className="col-4">
            <h5>Read time</h5>
            <p> 7 minute</p>
          </div>
          <div className="col-12 secoenddetailsblog mt-5">
            <h3>Abstract</h3>
            <p>
              RapidCure ST is 90% Volume Solids, Modified Cold Cured Epoxy,
              non-solvent type, designed for applications on substrates affected
              corroded by Acid Fumes & Exhibit excellent adhesion to abrasive
              blasted surface , cast iron and stainless steel. Excellent water,
              chemical, salt , oil resistance.
            </p>
          </div>
          <div className="col-6">
            <img
              src="https://agepcoatings.com/Uploads/CaseStudy/case1.png"
              className="img-fluid"
              alt="imgcoa"
            />
            <p>Initial Condition of Valves</p>
          </div>
          <div className="col-6">
            <img
              src="https://agepcoatings.com/Uploads/CaseStudy/case1.png"
              className="img-fluid"
              alt="imgcoa"
            />
          </div>
        </div>
      </div>
      <div className="container secoenddetailsblog">
        <div className="row">
          <div className="col-12 mt-5">
            <h3>Design Considerations</h3>
            <p>
              Following design parameters were considered while verifying
              suitability of RapidCure Sealer for the application.
            </p>
          </div>
          <div className="col-12 ">
            <table className="table table-striped table-bordered">
              <tbody>
                <tr>
                  <td>ACID STORAGE TANKS EXTERNAL</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Substrate</td>
                  <td>Carbon Steel, MS</td>
                </tr>
                <tr>
                  <td>Media</td>
                  <td>Acid Fumes</td>
                </tr>
                <tr>
                  <td>Design Temperature</td>
                  <td>1150? C</td>
                </tr>
                <tr>
                  <td>Operating Temperature</td>
                  <td>5? - 150? C</td>
                </tr>
              </tbody>
            </table>
            <p>
              Concerning the application to be applied following parameters were
              considered.
            </p>
            <table className="table table-striped table-bordered">
              <tbody>
                <tr>
                  <td>Existing Condition</td>
                  <td>New Valves</td>
                </tr>
                <tr>
                  <td>Suitability of Coatings</td>
                  <td>
                    Food Grade Epoxy and Doesn’t affect the drinking water
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="container secoenddetailsblog">
        <div className="row">
          <div className="col-12 mt-5">
            <h3>
              Additionally, following challenges were considered while selection
              of repair system.
            </h3>
            <ul>
              <li>
                Applicability of the system in high relative humidity conditions
                at the time of application/installation (85%)
              </li>
              <li>
                Temperature fluctuations during operation, requirement of
                flexibility in the system.
              </li>
            </ul>
            <p className="mt-2">
              Following comparison table provides suitability statement for
              RapidCure EP-F
            </p>
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th scope="col-4">Parameter</th>
                  <th scope="col-4">Design Parameter</th>
                  <th scope="col-4">RapidCure EP-F</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Type of system</td>
                  <td>-free Epoxy Lining SystemSolvent</td>
                  <td>Solvent-free Epoxy Lining System</td>
                </tr>
                <tr>
                  <td>Design Temperature</td>
                  <td>150? C</td>
                  <td>150? C</td>
                </tr>
                <tr>
                  <td>Long Term Durability Test</td>
                  <td>
                    BS:6920 vide report number 2011KL0677. Meets WRAS Schedule 2
                    Paragraph 2 & Guidance G2.2.
                  </td>
                  <td>
                    BS:6920 vide report number 2011KL0677. Meets WRAS Schedule 2
                    Paragraph 2 & Guidance G2.2.
                  </td>
                </tr>
                <tr>
                  <td>Application Temperature</td>
                  <td>Ambient 50? C</td>
                  <td>Ambient up to 150? C</td>
                </tr>
                <tr>
                  <td>Relative Humidity Tolerance</td>
                  <td>Max 85%</td>
                  <td>85%</td>
                </tr>
                <tr>
                  <td>Thickness (NACE No. 10)</td>
                  <td>100 micron in single coat DFT</td>
                  <td>100 micron in single coat DFT</td>
                </tr>
                <tr>
                  <td>Curing schedule required taking in operation</td>
                  <td>-</td>
                  <td>Unrestricted after touch-dry</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-4">
            <img
              src="https://agepcoatings.com/Uploads/CaseStudy/case1.png"
              className="img-fluid"
              alt="imgcoa"
            />
            <p>Primer Application</p>
          </div>
          <div className="col-4">
            <img
              src="http://localhost:3008/Uploads/CaseStudy/case1.png"
              className="img-fluid"
              alt="imgcoa"
            />
            <p>Top Coat Application</p>
          </div>
          <div className="col-4">
            <img
              src="http://localhost:3008/Uploads/CaseStudy/case1.png"
              className="img-fluid"
              alt="imgcoa"
            />
          </div>
          <div className="col-12">
            <h3>Design of the System</h3>
            <p>
              System is designed to meet food grade coatings and designed to
              give 3-5 Years of Service Life in the exposed environmental
              conditions i.e. weather conditions, ambient temperature changes.
            </p>
            <h3>Procedure Followed</h3>
            <ul>
              <li>
                Surface Preparation: Abrasive Blasting to Sa 2½ with surface
                profile of 40-75 micron
              </li>
              <li>
                Primer RapidCure EP-F to be applied as Primer upto 100 micron
                DFT.{" "}
              </li>
              <li>
                Top Coat - RapidCure EP-F to be applied as Top Coat upto 100
                micron DFT
              </li>
              <li>Surface to be left for curing.</li>
              <li>Application Completed.</li>
            </ul>
            <h3>Enclosure</h3>
            <p>RapidCure EP-F Data Sheet</p>
          </div>
        </div>
      </div>
      <div className="container firstdetailsblog">
        <div className="row">
          <div className="col-6">
            <img
              src="http://localhost:3008/Uploads/CaseStudy/case1.png"
              className="img-fluid"
              alt="imgcoa"
            />
          </div>
          <div className="col-6">
            <img
              src="http://localhost:3008/Uploads/CaseStudy/case1.png"
              className="img-fluid"
              alt="imgcoa"
            />
          </div>
        </div>
      </div> */}
      <Footer />
    </>
  );
};

export default SecoendBlog;
