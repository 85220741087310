import React from 'react'
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'
import Aboutslide from '../../Components/AboutusMain/AboutTop/Aboutslide'

const About = () => {
  return (
    <>
    <Header />
    <Aboutslide />
    <Footer />
    </>
  )
}

export default About