import React from "react";
import "./eightsection.css"
import img from "./flag.png"
const EightSection = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 client-section">
            <h2 className="text-uppercase">Testimonial</h2>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 ">
            <div className="firstsection our-client">
              <h4>Vijay Kumar Pandey</h4>
              <p>
              I am immensely pleased with the exceptional protective coatings for PPGCL Pipe Strengthening by AGEP Coatings. They have proven highly effective in repairing of cooling water pipe line  structure against the harsh conditions.
              </p>
              <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-6">
                <h6>4.8</h6>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <br/>
                <img src={img}  alt="_coating" width="65"/>
                <h6>Made In India</h6>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-6">
                <h6>A+</h6>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <h5>125 Ratings</h5>
                <p className="small">Customer Satisfaction Index</p>
              </div>
              </div>
             
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 ">
            <div className="firstsection our-client">
              <h4>OIL INDIA Limited</h4>
              <p>
              We are extremely satisfied with the oil-proofing fire cables provided by AGEP Coatings. They have demonstrated exceptional durability and reliability, ensuring our operations run smoothly and safely even in challenging conditions.
              </p>
              <div className="row">
              <div className="col-md-6 col-6">
                <h6>4.9</h6>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <br/>
                 <img src={img} alt="falgimg1" width="65"/> 
               
                <h6>Made In India</h6>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-6">
                <h6>A+</h6>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <h5>200 Ratings</h5>
                <p className="small">Customer Satisfaction Index</p>
              </div>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EightSection;
