import React from "react";
import "./footer.css";
import logo from "../../assets/Logo/logo.png";
import ScrollToTop from "../Main/ScrollToTop/ScrollToTop";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 text-center text-md-start mb-3 mb-md-0">
              <a className="navbar-brand" href="/#">
                <img
                  src={logo}
                  alt="Logo"
                  className="img-fluid"
                  width="100"
                  height="100"
                />
              </a>
             
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 text-center text-md-end">
              <p>
                <a href="/Contact" className="top-btn p-2">
                  Send Query
                </a>
              </p>
              <p className="footer-menu">
                <a href="/">Home</a>
                <a href="/Product">Products</a>
                <a href="/About">About</a>
                <a href="/Blog">Blog</a>
                <a href="/CaseStudy">Cases</a>
                <a href="/Contact">Contact</a>
              </p>
            </div>
          </div>
        </div>
      </footer>
      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xm-12 col-12 text-center footer-small">
        <p className="small"><a href="https://agepcoatings.com" >
                &copy; 2024 All Right Reserved Agep Coating Pvt Ltd</a>
              </p>
        </div>
      <ScrollToTop />
    </>
  );
};

export default Footer;
