import React, { useState } from "react";
import "./ScrollToTop.css"; // Make sure to create and style this CSS file
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import SubcontactForm from "../../ContactMain/ContactForm/SubcontactForm";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const ScrollToTop = () => {
  const [showForm, setShowForm] = useState(false);

  const handleButtonClick = () => {
    setShowForm(!showForm);
  };
  const handleCloseForm = () => {
    setShowForm(false);
  };
  // const [isVisible, setIsVisible] = useState(false);

  // const toggleVisibility = () => {
  //   if (window.pageYOffset > 300) {
  //     setIsVisible(true);
  //   } else {
  //     setIsVisible(false);
  //   }
  // };

  // const scrollToTop = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: 'smooth'
  //   });
  // };

  // useEffect(() => {
  //   window.addEventListener('scroll', toggleVisibility);
  //   return () => {
  //     window.removeEventListener('scroll', toggleVisibility);
  //   };
  // }, []);

  return (
    <>
      {/* <div className="scroll-to-top">
        <a href="/Contact" className="scroll-to-top-button">
       <FontAwesomeIcon icon={faPhone} style={{ color: '#fff' }} />

        </a>
    </div> */}

      <div className="scroll-to-top">
        <OverlayTrigger
          placement="left"
          overlay={<Tooltip id="button-tooltip">Get in touch</Tooltip>}
        >
          <button onClick={handleButtonClick} className="scroll-to-top-button">
            <FontAwesomeIcon icon={faPhone} style={{ color: "#fff" }} />
          </button>
        </OverlayTrigger>
      </div>
      {/* {showForm && (
        <div className="popup-form fade-in">

          <SubcontactForm onClose={handleCloseForm} />
        </div>
      )} */}
      {showForm && (
        <div className="popup-form fade-in">
          <button className="close-button" onClick={handleCloseForm}>
            &times;
          </button>
          <SubcontactForm />
        </div>
      )}
    </>
  );
};

export default ScrollToTop;
