import React from 'react'
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'
import ContactSlider from '../../Components/ContactMain/ContactSlider'

const Contact = () => {
  return (
    <>
    <Header />
    <ContactSlider />
    <Footer />
    </>
  )
}

export default Contact