import React from "react";
import Header from "../Header/Header";
import Blogssection from "./Blogssection";
import Sevensection from "../Main/SevenSection/Sevensection";
import Fourthsection from "../Main/Fourthsection/Fourthsection";
import "./blogsection.css";

const Blogmain = () => {
  return (
    <>
      <Header />
      <div className="container-fluid blogmain">
        <div className="row">
          <div className="blog-content">
            <h1>Insights & Solutions The AGEP Coatings Blog</h1>
            <a href="/Contact">
              <button type="button" className="slide-button text-light">
                Contact Now
              </button>
            </a>
          </div>
        </div>
      </div>
      <Blogssection />
      <Sevensection />
      <Fourthsection />
    </>
  );
};

export default Blogmain;
